import { CxEntityType, RuleDto, SelectorDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class RuleStore extends CxBaseStoreModule<RuleDto> {

    constructor() {
        super("rule")
        this.state.entityType = CxEntityType.RULE
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getSelectors: async (context: any, ruleId: number) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`rule/${ruleId}/selector`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            createSelector: async (context: any, params: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(`rule/${params.ruleId}/selector`, params.entity);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            updateSelector: async (context: any, params: any) => {
                context.state.loading = true;
                try {
                    let response = await api.put(`rule/${params.ruleId}/selector`, params.entity);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            deleteSelector: async (context: any, params: any) => {
                context.state.loading = true;
                try {
                    let response = await api.delete(`rule/${params.ruleId}/selector/${params.id}`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new RuleStore().createStore()