
import { defineComponent } from "vue";
import { CxTableColumn, CxTableConfig } from "@/types/cx-table-config";
import { CheckImportDto } from "@/types/dto";

export default defineComponent({
  props: {
    initials: {
      type: String,
      default: "CX",
    },
    dialogHeader: {
      type: String,
      default: "CSV Import",
    },
    acceptedFileType: {
      type: String,
      default: ".csv",
    },
    store: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      entity: {} as any,
      files: [] as any,
      loading: false,
      display: false,
      showInformation: false,
      formData: {} as any,
      importPayload: {} as any,
      tableHeader: [] as any,
      previewData: [] as any,
      checkImportDto: {} as CheckImportDto,
      showTable: false,
      importErrorData: {} as any,
      checkingLoading: false,
    };
  },

  computed: {
    tableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn(this.tableHeader[0], this.tableHeader[0]),
        new CxTableColumn(this.tableHeader[1], this.tableHeader[1]),
        new CxTableColumn(this.tableHeader[2], this.tableHeader[2]),
        new CxTableColumn(this.tableHeader[3], this.tableHeader[3]),
        new CxTableColumn(this.tableHeader[4], this.tableHeader[4]),
        new CxTableColumn(this.tableHeader[5], this.tableHeader[5]),
        new CxTableColumn(this.tableHeader[6], this.tableHeader[6]),
      ]);
      return tc;
    },
  },

  methods: {
    foundErrors() {
      if (Object.values(this.importErrorData).length > 0) {
        return Object.values(this.importErrorData).every(
          (val: any) => val.length === 0
        )
          ? false
          : true;
      }
      return true;
    },

    open() {
      (this.$refs as any)["file"].choose();
    },

    onFileChanged($ev: any) {
      if ((<any>this.$refs["file"]).files.length > 0) {
        this.files = (<any>this.$refs["file"]).files;
      }
    },

    importFile($ev: any) {
      if (this.files.length > 0) {
        this.loading = true;

        let file = this.files.find((x: any) => x !== undefined);
        this.formData = new FormData();
        this.formData.append("import-csv", file);

        if (this.formData) {
          this.importPayload.delimiter = this.entity.delimiter;
          this.importPayload.variable = this.entity.variable;
          this.importPayload.file = this.formData;

          this.$store
            .dispatch(`${this.store}/import`, this.importPayload)
            .then(() => {
              this.loading = false;
              this.$cx.notifyCreated(this.$t("import", "lb"));
            })
            .catch((error) => {
              this.loading = false;
              this.$cx.error(error.response.data, this.$cx.e("creating"));
            });
        }
      }
    },

    removeFile(file: any) {
      var index = this.files.indexOf(file);
      this.showTable = false;
      this.previewData = [];
      (<any>this.$refs["importError"]).closeImportErrors();
      this.files.splice(index, 1);
    },

    formatSize(bytes: any) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    checkImportData() {
      if (this.files.length > 0) {
        this.checkingLoading = true;

        let file = this.files.find((x: any) => x !== undefined);
        this.formData = new FormData();
        this.formData.append("file", file);

        if (this.formData) {
          this.importPayload.delimiter = this.entity.delimiter;
          this.importPayload.variable = this.entity.variable;
          this.importPayload.file = this.formData;

          this.$store
            .dispatch(`${this.store}/checkImport`, this.importPayload)
            .then((response) => {
              this.checkingLoading = false;
              this.$cx.notifyImportChecked(this.$t("importChecked", "lb"));
              this.checkImportDto.dataObjects = response.dataObjects;
              delete response.dataObjects;
              this.importErrorData = response;

              this.readCsvPreview(
                this.checkImportDto.dataObjects,
                this.importErrorData
              );
            })
            .catch((error) => {
              this.checkingLoading = false;
              this.$cx.error(
                error.response.data,
                this.$cx.e("importChecked")
              );
            });
        }
      }
    },
    readCsvPreview(dataObjects: any, errorObject: any) {
      this.tableHeader = Object.keys(dataObjects[0]);
      this.previewData = dataObjects;
      (<any>this.$refs["importError"]).showImportErrors();
      (<any>this.$refs["importError"]).dataArrays = errorObject;
      this.showTable = true;
    },
  },
});
