
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { DeviceFamilyDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        manufacturer: { required, $autoDirty: true },
      },
    };
  },
  data() {
    return {
      entity: {} as DeviceFamilyDto,
      initialState: {} as DeviceFamilyDto,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("deviceFamily/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      }
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("deviceFamily/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("deviceFamily", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("deviceFamily")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("deviceFamily/create", this.entity)
          .then((newEntity: DeviceFamilyDto) => {
            this.$cx.notifyCreated(this.$t("deviceFamily", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("deviceFamily")
            else
              this.$cx.goToById("deviceFamilyEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
  },
});
