import { RouteRecordRaw } from "vue-router"

import VariableEditor from "./VariableEditor.vue";

const routes: Array<RouteRecordRaw> = [
    // Variables
    {
        path: "/variables",
        name: "variableEditor",
        component: VariableEditor,
        meta: { menu: "variable" },
    },

]


export default routes