

//@ts-ignore
import ace from "ace-builds/src-min-noconflict/ace";

//@ts-ignore
import languageTools from "ace-builds/src-min-noconflict/ext-language_tools";

//@ts-ignore
import aceThemeTwilight from "ace-builds/src-min-noconflict/theme-twilight";

//@ts-ignore
import djangoMode from "ace-builds/src-noconflict/mode-django";

//@ts-ignore
import pythonMode from "ace-builds/src-noconflict/mode-python"

import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: {
            type: String,
        },
        lang: {
            type: String,
            default: "django"
        },
        height: {
            type: String,
            default: '50vh'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            uid: this.$cx.createUid(),
            editorInstance: {} as any,
        };
    },
    beforeUnmount() {
        if (this.editorInstance != null) this.editorInstance.destroy();
    },
    watch: {
        modelValue(v: any) {
            if (v != this.editorInstance.session.getValue()) {
                this.editorInstance.session.setValue(v, 1)
                this.editorInstance.resize()
            }
        },
        disabled(v: any) {
            if (this.editorInstance != null) {
                this.editorInstance.setReadOnly(v)
            }

        }
    },
    mounted() {
        ace.require(languageTools);
        let editorInstance = ace.edit(this.uid);
        editorInstance.setTheme(aceThemeTwilight);

        switch (this.lang.toLowerCase()) {
            case "django":
                editorInstance.session.setMode(new djangoMode.Mode());
                break;
            case "python":
                editorInstance.session.setMode(new pythonMode.Mode());
                break;
        }

        editorInstance.session.on("change", (v: any) => {
            this.$emit("update:modelValue", editorInstance.session.getValue());
        })

        editorInstance.setAutoScrollEditorIntoView(true)

        setTimeout(() => {
            editorInstance.env.editor.resize()
        }, 2000)

        this.editorInstance = editorInstance
    },
    methods: {
        getSelectedText() {
            return this.editorInstance.getSelectedText()
        },
        pasteText(text: any) {
            this.editorInstance.insert(text)
        },
        undo() {
            this.editorInstance.undo()
        },
        forceUpdateValue(v: any) {
            this.editorInstance.session.setValue(v, 1)
            this.editorInstance.resize()
        }
    }
});
