
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      os: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("filename", this.$t("os", "filename")),
        new CxTableColumn("version", this.$t("os", "version")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("os/getWithFilter", filters)
        .then((os) => {
          this.os = os;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("os/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      this.$cx.confirmDeleteDetail(
        data.name,
        () => {
          this.$store
            .dispatch("os/delete", data.id)
            .then(() => {
              this.$cx.notifyDeleted(this.$t("os", "lb"));
              (<any>this.$refs)['tbl'].reload()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
