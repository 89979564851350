import { CiscoPnpRequestDto } from "./../types/dto";
import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";

const entityName = "pnpRequest";
const cxApi = new CxApi<CiscoPnpRequestDto>(entityName);

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getPnpRequestsByLocationId(context: any, locationId: number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/location/${locationId}`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getPnpRequestsByLocationDeviceId(
      context: any,
      locationDeviceId: number
    ) {
      context.state.loading = true;
      try {
        let response = await api.get(
          `${entityName}/locationDevice/${locationDeviceId}`
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
