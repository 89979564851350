import { api } from "@/_config/api";
import { ManufacturerDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";

class ManufacturerStore extends CxBaseStoreModule<ManufacturerDto> {

  constructor() { super("manufacturer") }

  defineActions(actions: any) {
    return super.defineActions({
      getFamilies: async (context: any, id: number) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/${id}/deviceFamily`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      getContacts: async (context: any, id: number) => {
        context.state.loading = true;
        try {
          let response = await api.get(`${context.state.entityName}/${id}/contact`);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
      getImportTemplate: async (context: any) => {
        context.state.loading = true;
        try {
          let response = await api.get(context.state.entityName + `/importTemplate`);
          let attachment =
            response.headers["content-disposition"] ||
            response.headers["Content-Disposition"];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
            attachment!
          )![0];
          filename = filename.split("=")[1].replaceAll('"', "");
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          context.state.loading = false;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },

      getExport: async (context: any) => {
        context.state.loading = true;
        try {
          let response = await api.get(context.state.entityName + `/export?type=CSV`);
          let attachment =
            response.headers["content-disposition"] ||
            response.headers["Content-Disposition"];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
            attachment!
          )![0];
          filename = filename.split("=")[1].replaceAll('"', "");
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          context.state.loading = false;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },

      importData: async (context: any, payload: any) => {
        context.state.loading = true;
        try {
          let url = `/import/verify?delimiter=${payload.delimiter}`;
          if (payload.save) {
            url = `/import/save?delimiter=${payload.delimiter}`;
          }
          let response = await api.post(context.state.entityName + url, payload.file);
          context.state.loading = false;
          return response.data;
        } catch (error) {
          console.error(error);
          context.state.loading = false;
          return Promise.reject(error);
        }
      },
    })
  }
}

export default new ManufacturerStore().createStore()
