
import { cxUtils } from "@/types/cx-utils";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import { defineComponent } from "vue";
import { CxDataType, CxTableConfig } from "../types/cx-table-config";

export default defineComponent({
  props: {
    config: {
      type: CxTableConfig,
    },
    data: {
      type: Object,
    },
    loading: Boolean,
    tableStyle: {
      type: String,
      default: ""
    },
    stateKey: {
      type: String,
      default: ""
    },
    defaultFilters: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      uid: this.$cx.createUid(),
      CxDataType: CxDataType,
      utils: cxUtils,
      innerSelection: [] as any[],
      filters: {
        name: { value: null, matchMode: 'startsWith' },
      },
      filterState: {} as any,
      tableFilter: {
        page: 0,
        size: 25,
        filters: {} as any
      } as any,
      filterTimeout: null as any,
      initialState: null as any
    };
  },
  computed: {
    selection: {
      get(): any[] {
        return this.innerSelection;
      },
      set(v: any) {
        this.innerSelection = v;
        this.$emit("update:selection", v);
      },
    },
    totalRecords() {
      if (this.data == null) return 0
      return this.data.totalRecords
    },
    pageOffset() {
      return this.tableFilter.size * this.tableFilter.page
    }
  },
  created() {
    // reload Filter- and Sort-State from localStorage
    let state = this.$cx.getStorage('cx_tbl_' + this.stateKey, "")
    if (!this.$cx.isNullOrEmpty(state)) {
      this.initialState = JSON.parse(state)
      this.uid = this.initialState.uid
      this.filterState = this.initialState.filterState
      this.tableFilter.page = 0
      this.tableFilter.size = this.initialState.size
    }
  },
  mounted() {
    if (this.initialState != null) {
      // apply Filter- and Sort-State from initialState
      Object.keys(this.initialState.inputs).forEach(key => {
        let i = document.querySelector("#" + key) as any;
        if (i != null)
          i.value = this.initialState.inputs[key];
      });
      (<any>this.$refs)[this.uid].d_multiSortMeta = this.initialState.sort;
    }

    // trigger initial load in all cases
    this.$nextTick(() => {
      // apply default filtering once
      Object.entries(this.defaultFilters).forEach(f => {
        if (f[0].startsWith("s_"))
          (<any>this.$refs)[this.uid].d_multiSortMeta.push({ field: f[0].replace("s_", ""), order: (f[1] == 'asc') ? -1 : 1 })
      })

      this.refreshFilters()
    })
  },
  methods: {
    /**
     * Captures Pagination and stores- it inside tableFilter.
     * Calls refreshFilters.
     */
    onRefreshFilter(ev: any) {
      if ("pageCount" in ev)
        this.tableFilter.size = ev.rows
      if ("page" in ev)
        this.tableFilter.page = ev.page
      this.refreshFilters()
    },
    /**
     * Captures a keypress on a filter input.
     * Stores the field inside filterState and calls refreshFilters 
     */
    onFilter(ev: any, field: any) {
      clearTimeout(this.filterTimeout)
      this.filterTimeout = setTimeout(() => {
        this.tableFilter.page = 0
        if (this.$cx.isNullOrEmpty(ev.target.value))
          delete this.filterState['f_' + field]
        else
          this.filterState['f_' + field] = ev.target.value
        this.refreshFilters()
      }, 250)
    },
    /**
     * Recalculates Filter- and Sort-State.
     * Emits the filter event.
     */
    refreshFilters() {
      this.tableFilter.filters = Object.assign({}, this.filterState);
      (<any>this.$refs)[this.uid].d_multiSortMeta.forEach((o: any) => {
        this.tableFilter.filters['s_' + o.field] = (o.order == 1) ? 'desc' : 'asc'
      });
      this.storeState()
      this.$emit("filter", this.tableFilter);
    },
    clearFilters() {
      (<any>this.$refs)[this.uid].d_multiSortMeta.length = 0;
      document.querySelectorAll("input[id^='f_" + this.uid + "_']").forEach((el: any) => {
        el.value = null
      })
      this.filterState = {}
      this.refreshFilters()
    },
    storeState() {
      if (this.$cx.isNullOrEmpty(this.stateKey)) return;
      this.initialState = {
        uid: this.uid,
        inputs: {},
        sort: (<any>this.$refs)[this.uid].d_multiSortMeta,
        filterState: this.filterState,
        page: this.tableFilter.page,
        size: this.tableFilter.size
      }
      document.querySelectorAll("input[id^='f_" + this.uid + "_']").forEach((el: any) => {
        this.initialState.inputs[el.id] = el.value
      })
      localStorage.setItem("cx_tbl_" + this.stateKey, JSON.stringify(this.initialState))
    },
    reload() {
      this.refreshFilters()
    },
    getNoDataLabel() {
      if (this.loading)
        return this.$c('loadData')
      return this.$c('noData')
    }
  }
});
