
import { VariableValueDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { required } from "@/_config/ui-framework";
import { CxVariableType } from "@/types/cx-variable-type";
import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";

export default defineComponent({
    props: {
        editorApi: {
            type: Object as PropType<CxFormApi>,
        },
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        },
    },
    data() {
        return {
            id: -1 as number,
            variableTypes: CxVariableType,
            entity: {} as VariableValueDto,
        };
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                value: { required, $autoDirty: true },
                variable: { required, $autoDirty: true },
                variableType: { required, $autoDirty: true },
            },
        };
    },
    computed: {
        isEditing() {
            return this.id > 0;
        },
        disabled() {
            if (this.grant == null) return false
            if (this.grant.perm == CxPermission.NONE) return false
            return !this.$auth.permits(this.grant.perm, this.grant.lvl)
        }
    },
    created() {
        this.editorApi!.onSave = async (closeFn: any) => {
            if (await this.$cx.notifyValidationError(this.v$)) return false;
            if (this.entity.variableType!.name != CxVariableType.Global) {
                if (this.entity.variableValueRels == null || this.entity.variableValueRels.length < 1) {
                    this.$cx.warn(this.$cx.e("validating"), this.$cx.e('levelObjects'))
                    return
                }
            }

            if (this.isEditing) {
                this.$store
                    .dispatch("variableValue/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("variableValue", "lb"));
                        closeFn();
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.$store
                    .dispatch("variableValue/create", this.entity)
                    .then((data) => {
                        this.$cx.notifyCreated(this.$t("variableValue", "lb"));
                        this.editorApi!.load(data.id);
                        closeFn()
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        };
        this.editorApi!.load = (id: number) => {
            this.id = id;
            this.$store.dispatch("variableValue/getById", id).then((entity) => {
                this.entity = entity;
            });
        };
        this.editorApi!.delete = (id: number) => {
            this.$store.dispatch("variableValue/delete", id).then(() => {
                this.$cx.notifyDeleted(this.$t("variableValue", "lb"));
            });
        };
        this.editorApi!.reset = () => {
            this.entity = {} as VariableValueDto;
        };
        this.editorApi!.isEditing = () => this.id > 0;
    },
    methods: {
        variableTypeIs(type: CxVariableType) {
            if (this.entity == null) return false
            if (this.entity.variableType == null) return false
            return this.entity.variableType.name == type
        },
        onVariableTypeChanged() {
            this.entity.variableValueRels = []
        }
    }
});
