

import { defineComponent } from "vue";
import { ApprovalDto, CxApprovalState, CxConfig, CxConfigResult, CxEntityType } from "@/types/dto";
import configScopeParameters from "@/_config/config-scope-parameters";
import useVuelidate from "@vuelidate/core";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
  props: {
    id: String
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        weight: { required, $autoDirty: true }
      },
    };
  },
  data() {
    return {
      errors: "",
      entity: {} as CxConfig,
      initialEntity: {} as CxConfig,
      variables: {} as any,
      variableGraph: [...configScopeParameters],
      isNoConfigColumnExpanded: this.$cx.getStorageBool('cx.noConfigExpanded', true),
      locationDeviceName: "",
      approvalState: new ApprovalState(CxEntityType.CONFIGURATION),
      accordionIndizes: [] as any,
      suppressUnsavedChanges: false
    };
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.suppressUnsavedChanges) {
      next()
      return
    }
    this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
  },
  mounted() {
    for (var variable of this.variableGraph) {
      this.variables["{{" + variable.label] = {};
      this.toMapRecursively(variable, this.variables["{{" + variable.label]);
    }

    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    isApproval() {
      return this.$cx.isApprovalRoute(this.$router)
    }
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.approvalState.setStore("configuration", this.id!)

        if (this.isApproval) {
          this.approvalState.setStore("configuration", this.id!)
          this.approvalState.fetchNewDataset().then((data: ApprovalDto) => {
            this.entity = data.data
            this.initialEntity = this.$cx.getState(this.entity)
          })
        } else {
          this.$store
            .dispatch("configuration/getById", this.id)
            .then((entity) => {
              this.entity = entity;
              this.initialEntity = this.$cx.getState(this.entity);
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

          this.approvalState.fetchApprovalData()
        }
      } else {
        try {
          // check if config text is provided
          if ("text" in this.$route.query) {
            let text = JSON.parse(atob(this.$route.query.text!.toString())).text
            if (!this.$cx.isNullOrEmpty(text)) {
              this.entity.configurationText = text
              this.$cx.goTo('configurationEditorNew')
            }
          }
        } catch { }

        // open header tab by default
        this.accordionIndizes = [0]
      }

      this.$store.dispatch("configuration/getVariables")
        .then(variables => {
          this.variableGraph.push({
            key: 'variable',
            label: 'variable',
            children: variables.map((v: string) => ({
              key: this.$cx.createUid(),
              label: v
            }))
          })
        })
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return false;

      if (this.isEditing) {
        this.$store
          .dispatch("configuration/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("configuration", "lb"));
            this.initialEntity = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("configuration")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("configuration/create", this.entity)
          .then((newEntity: CxConfig) => {
            this.$cx.notifyCreated(this.$t("configuration", "lb"));
            this.entity = newEntity;
            this.initialEntity = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("configuration")
            else
              this.$cx.goToById("configurationEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
    toMapRecursively(graph: any, target: any) {
      for (var child of graph.children) {
        if (child.children != null) {
          target[child.label] = {};
          if (child.children != null) {
            this.toMapRecursively(child, target[child.label]);
          }
        } else {
          target[child.label + " }}"] = {};
        }
      }
      return target;
    },
    compileTemplate() {
      this.errors = ""
      this.$store
        .dispatch("configuration/compile", this.entity.configurationText)
        .then(() => {
          this.$cx.success(this.$t('configuration', 'lb'), this.$c('noErrors'))
        }).catch(error => {
          this.errors = this.$cx.e('error') + "\n" + error?.response?.data?.message
          this.$cx.error(error)
        })
    },
    toggleNoConfigColumn() {
      this.isNoConfigColumnExpanded = !this.isNoConfigColumnExpanded
      localStorage.setItem("cx.noConfigExpanded", this.isNoConfigColumnExpanded.toString())
    },
    compileLastLocationDevice() {
      let locationDeviceId = this.$cx.getStorageInt("cx.lastCompiledLocationDeviceId", -1)
      this.locationDeviceName = localStorage.getItem("cx.lastCompiledLocationDeviceName") || ""
      if (locationDeviceId == -1) return
      this.$store
        .dispatch("locationDevice/generate", locationDeviceId)
        .then((config: CxConfigResult) => {
          (this.$refs as any)["configDialog"].show({ id: locationDeviceId, name: this.locationDeviceName }, config)
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("generating")));
    },
    onApprovalViewChanged(data: any, isViewingChanges: boolean) {
      if (isViewingChanges) {
        (this.$refs as any)['approval'].apply(this.entity, data)
        //this.accordionIndizes = [0, 1]
      } else {
        (this.$refs as any)['approval'].apply(this.entity, this.initialEntity)
      }
    },

    onApprovalChanged(state: CxApprovalState, id: string) {
      if (state == CxApprovalState.APPROVED) {
        this.$cx.goToById("configurationEditor", parseInt(id))
        return
      }

      if (state == CxApprovalState.REJECTED && this.isApproval) {
        this.$cx.goTo("configuration")
        return
      }

      if (!this.isEditing) {
        this.suppressUnsavedChanges = true
        this.$cx.goToApproval("configurationEditor", id)
        return
      }
      (this.$refs as any)['approval'].reset()
      this.load()
    }


  },
});
