
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        modelValue: [Date, String, Object],
        height: {
            type: String,
            default: 'calc(100vh - 150px)'
        }
    },
    data() {
        return {
            filters: {} as any,
            selectionKeys: [] as any,
            expandedKeys: {} as any,
            treeData: [],
            treeDataMap: {} as { [key: string]: any },
            selectedNodes: [] as any
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.onModelValueChanged(this.modelValue!)
    },
    computed: {
        selectedCount() {
            return this.selectedNodes.length
        }
    },
    watch: {
        modelValue: function (newValue: any) {
            this.onModelValueChanged(newValue)
        },
        selectionKeys(v: any) {
            this.selectedNodes.length = 0
            Object.keys(v).forEach((nodeKey: any) => {
                if (nodeKey.startsWith('ld_')) {
                    this.selectedNodes.push({
                        id: this.treeDataMap[nodeKey].id,
                        name: this.treeDataMap[nodeKey].data.name
                    })
                }
            })
            this.$emit('update:modelValue', this.selectedNodes)
        }
    },
    methods: {
        load() {
            this.$store.dispatch("locationDevice/getForPicker")
                .then((nodes) => {
                    this.treeData = nodes
                    // flatten the tree into a map for efficient selectionKey lookup
                    nodes.forEach((l: any) => {
                        if (l.key.startsWith('l_')) {
                            l.children.forEach((ld: any) => {
                                if (!(ld.key in this.treeDataMap)) {
                                    let newLd = Object.assign({}, ld)
                                    newLd.location = {
                                        id: l.id,
                                        key: l.key,
                                        data: l.data
                                    }
                                    this.treeDataMap[ld.key] = newLd
                                }
                            })
                        }
                    })
                    this.onModelValueChanged(this.modelValue!)
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        expandAll() {
            for (let node of this.treeData)
                this.expandNode(node);
            this.expandedKeys = { ...this.expandedKeys };
        },
        expandNode(node: any) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;
                for (let child of node.children)
                    this.expandNode(child);
            }
        },
        onModelValueChanged(newValue: any) {
            if (this.modelValue == null) {
                this.selectedNodes.length = 0;
                this.selectionKeys = {}
            }
            else {
                newValue.forEach((node: any) => {
                    if (Object.keys(this.treeDataMap).length > 0) {
                        this.selectionKeys['ld_' + node.id] = { checked: true, partialChecked: false }
                        this.selectionKeys[this.treeDataMap['ld_' + node.id].location.key] = { checked: true, partialChecked: false }
                    }
                })
                this.selectedNodes = newValue
            }

        }
    },
});
