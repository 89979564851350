import { RouteRecordRaw } from "vue-router"

import Role from "./Role.vue";

import ApiParam from "./apiParam/ApiParam.vue";
import ApiParamEditor from "./apiParam/ApiParamEditor.vue";

import LoginProfile from "./loginProfile/LoginProfile.vue";
import LoginProfileEditor from "./loginProfile/LoginProfileEditor.vue";

const routes: Array<RouteRecordRaw> = [
    // roles
    {
        path: "/role",
        name: "role",
        component: Role,
        meta: { menu: "management" },
    },

    // profiles
    {
        path: "/loginProfile",
        name: "loginProfile",
        component: LoginProfile,
        meta: { menu: "management" },
    },
    {
        path: "/loginProfile/new",
        name: "loginProfileEditorNew",
        component: LoginProfileEditor,
        meta: { menu: "management" },
    },
    {
        path: "/loginProfile/:id",
        name: "loginProfileEditor",
        component: LoginProfileEditor,
        meta: { menu: "management" },
        props: true,
    },

    // Api-Params
    {
        path: "/apiParam",
        name: "apiParam",
        component: ApiParam,
        meta: { menu: "management" },
    },
    {
        path: "/apiParam/new",
        name: "apiParamEditorNew",
        component: ApiParamEditor,
        meta: { menu: "management" },
    },
    {
        path: "/apiParam/:id",
        name: "apiParamEditor",
        component: ApiParamEditor,
        meta: { menu: "management" },
        props: true,
    }
]

export default routes