import { RouteRecordRaw } from "vue-router"

import Job from "./Job.vue"
import JobEditor from "./JobEditor.vue";
import JobOverview from "./JobOverview.vue"

import CodeTemplate from "./codeTemplate/CodeTemplate.vue";
import CodeTemplateEditor from "./codeTemplate/CodeTemplateEditor.vue";

import DayNWizard from "./dayN/DayNWizard.vue"

import Schedule from "./schedule/Schedule.vue"
import ScheduleEditor from "./schedule/ScheduleEditor.vue"

const logRoutes: Array<RouteRecordRaw> = [

    // DayN
    {
        path: '/dayn',
        name: 'dayn',
        component: DayNWizard,
        meta: { menu: "job" }
    },

    {
        path: '/jobs',
        name: 'jobs',
        component: JobOverview,
        meta: { menu: "job" }
    },

    // Schedule
    {
        path: "/schedule",
        name: "schedule",
        component: Schedule,
        meta: { menu: "job" },
    },
    {
        path: "/schedule/new",
        name: "scheduleEditorNew",
        component: ScheduleEditor,
        meta: { menu: "job" },
    },
    {
        path: "/schedule/:id",
        name: "scheduleEditor",
        component: ScheduleEditor,
        meta: { menu: "job" },
        props: true,
    },

    // Code-Template
    {
        path: "/codeTemplate",
        name: "codeTemplate",
        component: CodeTemplate,
        meta: { menu: "job" },
    },
    {
        path: "/codeTemplate/new",
        name: "codeTemplateEditorNew",
        component: CodeTemplateEditor,
        meta: { menu: "job" },
    },
    {
        path: "/codeTemplate/:id",
        name: "codeTemplateEditor",
        component: CodeTemplateEditor,
        meta: { menu: "job" },
        props: true,
    },

    // Job
    {
        path: "/job",
        name: "job",
        component: Job,
        meta: { menu: "job" },
    },
    {
        path: "/job/new",
        name: "jobEditorNew",
        component: JobEditor,
        meta: { menu: "job" },
    },
    {
        path: "/job/:id",
        name: "jobEditor",
        component: JobEditor,
        meta: { menu: "job" },
        props: true,
    },
]

export default logRoutes