
import { ApprovalState } from "@/types/approval-state";
import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";
import { CxApprovalState, CxEntityType } from "@/types/dto";
import SplitButton from "primevue/splitbutton";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        isEditing: {
            type: Boolean
        },
        canCreate: {
            type: Boolean,
            default: false
        },
        isNewDataset: {
            type: Boolean,
            default: false
        },
        disableSaveAndBack: {
            type: Boolean,
            default: true
        },
        v$: {
            type: Object,
            default: null
        },
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        },
        approvalState: {
            type: Object as PropType<ApprovalState>,
            default: new ApprovalState(CxEntityType.GLOBAL)
        },
        entity: Object,
        initialEntity: Object
    },
    data() {
        return {
            comment: "",
            prevComment: "",
            status: "",
            approvalDialogVisible: false,
            approvalDialogHeader: 'Freigeben',
            approvalDialogSaveLabel: 'Freigeben',
            approvalDialogText: '',
            approvalDialogSaveClass: '',
            approvalDialogIcon: '',
            prevCommentVisible: false
        }
    },
    methods: {
        onCancel() {
            this.approvalDialogVisible = false
        },
        onClick(ev: any) {
            if (!this.enabled)
                this.$emit('cancel')
            else if (this.isEditing && !this.disableSaveAndBack)
                this.$emit('save', true)
            else
                this.$emit('save', false)
        },
        async onOpenDialog(status: string) {
            this.status = status
            this.comment = ""
            this.prevCommentVisible = true
            this.prevComment = this.approvalState.approvalData.comment || ""
            switch (this.status) {
                case "request":

                    if (!(await this.isValid()))
                        return

                    if (this.isEditing) {
                        let changes = this.$cx.deltaChanges(this.initialEntity, this.entity)
                        if (Object.keys(changes).length < 1) {
                            this.$cx.error(null, this.$cx.e('noChanges'), this.$cx.e('noChanges1'))
                            return
                        }
                    }

                    this.prevCommentVisible = false
                    this.approvalDialogIcon = 'pi pi-arrow-up'
                    this.approvalDialogSaveClass = 'p-button-primary'
                    this.approvalDialogHeader = this.$t('approval', 'request')
                    this.approvalDialogSaveLabel = this.$t('approval', 'send')
                    if (this.isEditing) {
                        this.approvalDialogText = this.$t('approval', 'send1')
                    } else {
                        this.approvalDialogText = this.$t('approval', 'send2')
                    }
                    break;
                case "approve":
                    this.approvalDialogIcon = 'pi pi-save'
                    this.approvalDialogSaveClass = 'p-button-success'
                    this.approvalDialogHeader = this.$t('approval', 'approveAndSave')
                    this.approvalDialogSaveLabel = this.$t('approval', 'approve')
                    this.approvalDialogText = this.$t('approval', 'send3')
                    break;
                case "reject":
                    this.approvalDialogIcon = 'pi pi-times'
                    this.approvalDialogSaveClass = 'p-button-danger'
                    this.approvalDialogHeader = this.$t('approval', 'reject1')
                    this.approvalDialogSaveLabel = this.$t('approval', 'reject2')
                    this.approvalDialogText = this.$t('approval', 'reject3')
                    break;
                case "review":
                    this.approvalDialogIcon = 'pi pi-arrow-right'
                    this.approvalDialogSaveClass = 'p-button-danger'
                    this.approvalDialogHeader = this.$t('approval', 'review1')
                    this.approvalDialogSaveLabel = this.$t('approval', 'review2')
                    this.approvalDialogText = this.$t('approval', 'review3')
                    break;
            }
            this.approvalDialogVisible = true
        },
        onSaveApproval() {
            this.approvalState.approvalData.comment = this.comment;
            switch (this.status) {
                case "request":
                    this.approvalState.setData(this.entity)
                    this.approvalState.changeState(CxApprovalState.PENDING)
                    this.approvalState.commit().then((response) => {
                        this.approvalDialogVisible = false
                        this.$cx.success(this.$t('approval', 'success'), this.$t('approval', 'success1'))
                        this.$emit("stateChanged", CxApprovalState.PENDING, response.id)
                    }).catch(ex => {
                        this.$cx.error(ex, this.$cx.e('error'))
                    })
                    break;
                case "approve":
                    this.$store.dispatch(this.approvalState.store! + "/approve", this.approvalState.approvalData)
                        .then((response) => {
                            this.approvalState.approve()
                            this.approvalDialogVisible = false
                            this.$cx.success(this.$t('approval', 'success2'), this.$t('approval', 'success3'))
                            this.$emit("stateChanged", CxApprovalState.APPROVED, response?.id)
                        }).catch(ex => {
                            this.$cx.error(ex, this.$cx.e('error'))
                        })
                    break;
                case "reject":
                    this.approvalState.changeState(CxApprovalState.REJECTED)
                    this.approvalState.commit().then(() => {
                        this.approvalDialogVisible = false
                        this.$cx.warn(this.$t('approval', 'success4'), this.$t('approval', 'success5'), 5000)
                        this.$emit("stateChanged", CxApprovalState.REJECTED)
                    }).catch(ex => {
                        this.$cx.error(ex, this.$cx.e('error'))
                    })
                    break;
                case "review":
                    this.approvalState.changeState(CxApprovalState.REVIEW)
                    this.approvalState.commit().then(() => {
                        this.approvalDialogVisible = false
                        this.$cx.warn(this.$t('approval', 'success6'), this.$t('approval', 'success7'), 5000)
                        this.$emit("stateChanged", CxApprovalState.REVIEW)
                    }).catch(ex => {
                        this.$cx.error(ex, this.$cx.e('error'))
                    })
                    break;
            }
        },
        async isValid() {
            if (this.v$ == null) return true
            if (await this.$cx.notifyValidationError(this.v$)) return false;
            return true
        }
    },
    computed: {
        saveLabel() {
            return this.$c("save")
        },
        saveAndBackLabel() {
            return this.$c("saveAndBack")
        },
        items() {
            if (!this.enabled) return []
            if (this.isEditing) {
                let items = []
                if (!this.disableSaveAndBack) {
                    items.push({
                        label: this.saveLabel,
                        icon: 'pi pi-save',
                        command: () => this.$emit('save', false)
                    })
                }
                items.push(
                    {
                        label: this.$c("back"),
                        icon: 'pi pi-arrow-left',
                        command: () => this.$emit('cancel')
                    })
                return items
            }

            return [{
                label: this.$c("createAndBack"),
                icon: 'pi pi-plus',
                command: () => this.$emit('save', true)
            },
            {
                label: this.$c("back"),
                icon: 'pi pi-arrow-left',
                command: () => this.$emit('cancel')
            }]
        },
        enabled() {
            return this.$auth.permits(this.grant.perm, this.grant.lvl)
        },
        label() {
            if (!this.enabled)
                return this.$c("back")

            if (this.isEditing) {
                if (this.disableSaveAndBack)
                    return this.saveLabel
                else
                    return this.saveAndBackLabel
            } else
                return this.$c("saveButtonCreate")
        },
        icons() {
            if (!this.enabled)
                return 'pi pi-arrow-left'
            if (this.isEditing)
                return 'pi pi-save'
            return 'pi pi-plus'
        },
        classes() {
            return 'align-self-end'
        }
    },
});
