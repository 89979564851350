import { CxEntityType, DeviceTemplateDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class DeviceTemplateStore extends CxBaseStoreModule<DeviceTemplateDto> {

    constructor() {
        super("deviceTemplate")
        this.state.entityType = CxEntityType.DEVICE_TEMPLATE
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getAllByFilter: async (context: any, filters: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(context.state.entityName + '/ddfilter', { filters: filters });
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            getDevicePortsByDeviceTemplateId: async (context: any, deviceTemplateId: number) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/${deviceTemplateId}/ports`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            getModels: async (context: any, deviceTemplateId: number) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/${deviceTemplateId}/models`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new DeviceTemplateStore().createStore()