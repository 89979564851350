import CxApi from "@/types/cx-api";
import { api } from "@/_config/api";
import { CiscoMerakiNetworkDto, CiscoMerakiRequest } from "@/types/dto";

const entityName = "meraki/networks";
const merakiApiName = "organizations/"
const merakiNetwork = new CxApi<CiscoMerakiNetworkDto>(merakiApiName);

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {
        async create(context: any, params: CiscoMerakiRequest<CiscoMerakiNetworkDto>) {
            context.state.loading = true;

            try {
                let response = await api.post(`${entityName}/create`, params);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
    }
}