import globalStore from '@/store-modules';
import { CxPermission, CxPermissionLevel } from '@/types/cx-permission';
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { App, Plugin, ref, Ref } from 'vue';
import { api } from './api';
import { apiExtender } from './api-extender';
import { cxUtils } from '@/types/cx-utils';
import { CxEntityType } from '@/types/dto';

// @ts-ignore
import config from '../assets/env.js'

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $auth: Auth;
        $perm: typeof CxPermission;
        $type: typeof CxEntityType;
        $lvl: typeof CxPermissionLevel
    }
}

interface AuthOptions extends KeycloakConfig { }

class Auth {

    options: AuthOptions
    perm = CxPermission
    level = CxPermissionLevel
    permissions: Ref<string> = ref('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')

    stateInterval: any = null
    state: Ref<any> = ref({})
    nameInitials: Ref<String> = ref('cx')
    username: Ref<String> = ref('Conex')
    fullname: Ref<String> = ref('Conex')
    email: Ref<String> = ref('conex@conex')
    roles: Ref<any[]> = ref([])

    keycloak: Keycloak
    keycloakOptions?: KeycloakInitOptions

    constructor(options: AuthOptions = {
        clientId: config.VUE_APP_AUTH_CLIENTID || process.env.VUE_APP_AUTH_CLIENTID,
        realm: config.VUE_APP_AUTH_REALM || process.env.VUE_APP_AUTH_REALM,
        url: config.VUE_APP_AUTH_URL || process.env.VUE_APP_AUTH_URL
    }) {
        this.options = options
        this.keycloak = new Keycloak(options)
    }

    initialize(options: KeycloakInitOptions = {
        onLoad: 'login-required',
        checkLoginIframe: false,
        responseMode: 'query',
        token: cxUtils.getStorage("cx.token", "")
    }) {
        this.keycloakOptions = options
        // wrap the promise from keycloak and update
        // the jwt token in the axios headers globally
        return new Promise(async (resolve, reject) => {
            try {
                let result = await this.keycloak.init(options)
                this.keycloak.onTokenExpired = () => {
                    this.keycloak.updateToken(30).then(() => {
                        console.log("token refreshed")
                    }).catch((err) => {
                        console.log("token err", err)
                    })
                }
                if (result) {
                    (<any>api.defaults.headers)["Authorization"] = 'Bearer ' + this.keycloak.token!;
                    (<any>apiExtender.defaults.headers)["Authorization"] = 'Bearer ' + this.keycloak.token!;
                    localStorage.setItem("cx.token", this.keycloak.token!)

                    try {
                        await this.loadState()
                    } catch { }

                    this.loadStateInterval()
                    resolve(result)
                } else
                    reject(result)
            } catch (error) {
                console.error(error)
                reject(error)
            }
        })
    }

    permits(permission: CxPermission, level: CxPermissionLevel) {
        return this.permissions.value.charCodeAt(permission) >= level.charCodeAt(0)
    }

    approvalRequired(approval: string) {
        if (cxUtils.isNullOrEmpty(approval)) return false
        if (this.state.value?.props?.approvalEntities[approval] != null)
            return this.state.value.props.approvalEntities[approval]
        return false
    }

    get approvalEntities(): { [key: string]: boolean } {
        if (this.state.value?.props?.approvalEntities != null)
            return this.state.value.props.approvalEntities
        return {}
    }

    cssEditIcon(permission: CxPermission, level: CxPermissionLevel) {
        if (this.permits(permission, level))
            return 'pi-pencil'
        return 'pi-eye'
    }

    loadState() {
        return new Promise((resolve, reject) => {
            globalStore.dispatch("appContext/getState").then((state) => {
                this.state.value = state

                if (state.user == null) {
                    resolve(state)
                    return
                }
                this.email.value = state.user.email
                this.roles.value = state.user.roles
                this.username.value = state.user.username
                this.fullname.value = `${state.user.firstname} ${state.user.lastname}`
                this.nameInitials.value = `${state.user.firstname[0].toUpperCase()}${state.user.lastname[0].toUpperCase()}`
                if (state.user != null
                    && state.user.attributes != null
                    && state.user.attributes.permissions != null
                    && state.user.attributes.permissions.length > 0) {
                    this.permissions.value = state.user.attributes.permissions[0]
                }
                resolve(state)
            })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    loadStateInterval() {
        clearInterval(this.stateInterval)
        this.stateInterval = setInterval(() => {
            this.loadState()
        }, 10000)
    }

    logout() {
        this.keycloak.logout(this.keycloakOptions).then(() => {
            console.log("successfully logged out")
        }).catch((err) => {
            console.log("err logging out", err)
        })
    }
}

const authInstance = new Auth()

export { authInstance }

export const auth: Plugin = {
    install(app: App) {
        app.config.globalProperties.$auth = authInstance
        app.config.globalProperties.$perm = CxPermission
        app.config.globalProperties.$type = CxEntityType
        app.config.globalProperties.$lvl = CxPermissionLevel
    }
}