
/**
 * Allow pipeline specific overrides
 */
const config = (() => {
    return {
      // "VUE_APP_API_URL": "",
      // "VUE_APP_AUTH_CLIENTID": ""
      // "VUE_APP_AUTH_REALM": ""
      // "VUE_APP_AUTH_URL": ""
      // "VUE_CLEAR_STORAGE_AFTER": ""
      // "VUE_APP_EXTENDER_CUSTOMER": ""
      // "VUE_APP_USE_AUTH": "0"
      // "VUE_APP_IS_DEBUG": "1"
      // "VUE_APP_ENABLE_SATELLITE": "1"
      // "VUE_APP_EXTENDER_API_URL": ""
    };
  })();

export default config