
import { defineComponent, ref } from "vue";

export default defineComponent({
    props: {
        data: {
            type: Array,
            default: []
        },
        height: {
            type: String,
            default: 'calc(100vh - 300px)'
        },
        visibleRows: {
            type: Number,
            default: 5
        }
    },
    computed: {
        selectedJobDevices() {
            return this.selectedDevices
                .filter(s => !this.$cx.isNullOrEmpty(s.locationDeviceName))
                .map(d => ({ id: d.locationDeviceId, name: d.locationDeviceName }))
        }
    },
    data() {
        return {
            selectedDevices: [] as any[],
            softwareTableDataFiltered: [] as any,
            softwareDetailFilters: ref({
                locationName: { value: null, matchMode: 'contains' },
                deviceRoles: { value: null, matchMode: 'NameFromArray' },
                deviceFamilies: { value: null, matchMode: 'NameFromArray' },
                deviceModels: { value: null, matchMode: 'NameFromArray' },
                locationDeviceName: { value: null, matchMode: 'contains' },
                managementIp: { value: null, matchMode: 'contains' },
                sourceVersion: { value: null, matchMode: 'contains' },
                targetVersion: { value: null, matchMode: 'contains' },
                valid: { value: null, matchMode: 'contains' }
            } as { [key: string]: any }),
        };
    },
    methods: {
        load(locationId: number = -1) {
            if (locationId > 0)
                this.softwareTableDataFiltered = this.data.filter((d: any) => d.locationId == locationId)
            else
                this.softwareTableDataFiltered = this.data
        },
        async goToScheduler() {
            try {
                let templ = await this.$store.dispatch("codeTemplate/getByName", 'CX-cisco_ios-dayn_software')
                this.$cx.goToNewTabWithArgs("scheduleEditorNew", {
                    args: btoa(JSON.stringify({
                        name: 'Software-Update-' + this.$cx.createUid(),
                        codeTemplate: {
                            id: templ.id,
                            name: templ.name
                        },
                        devices: this.selectedJobDevices
                    }))
                })
            } catch (error) {
                this.$cx.error(error, this.$cx.e("loading"))
            }
        }
    },
});
