

import { CxConfigResult, LocationDeviceDto } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        showSaveHint: {
            type: Boolean,
            default: false
        },
        highlightConfigId: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            device: {} as LocationDeviceDto,
            config: {} as CxConfigResult,
            visible: false,
            isGridView: true,
            expandedKeys: {} as any,
            variables: [] as any
        };
    },
    computed: {
        getRowView() {
            let configs = []
            for (let i = 0; i < this.config.result!.length; i++) {
                configs.push({
                    right: this.config.result![i],
                    left: this.config.source![i]
                })
            }
            return configs
        },
        getSourceText() {
            return this.config.source!.map(r => r.text).join('\n')
        },
        getResultText() {
            return this.config.result!.map(r => r.text).join('\n')
        }
    },

    methods: {
        show(device: LocationDeviceDto, config: CxConfigResult) {
            this.device = device
            this.config = config
            this.visible = true
            this.variables = this.$cx.mapObjectToTree(this.config.variables, null)
        },
        onOpenConfig(configId: any) {
            this.$cx.goToByIdNewTab("configurationEditor", configId)
        },
        expandAll() {
            for (let node of this.variables) {
                this.expandNode(node);
            }

            this.expandedKeys = { ...this.expandedKeys };
        },
        expandNode(node: any) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        }
    },
});
