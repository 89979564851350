
import {
  CxTableConfig,
  CxTableColumn,
  CxDataType,
} from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      customers: [],
      filters: { global: {} } as any,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    tableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn("id", "ID"),
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
      tc.filters = this.filters;
      return tc;
    },
  },
  methods: {
    load() {
      this.$store
        .dispatch("customer/getAll")
        .then((customers) => {
          this.customers = customers;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    sync() {
      this.$store
        .dispatch("customer/sync")
        .then(() => {
          this.load();
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("syncing")));
    },
  },
});
