
import { defineComponent } from "vue";

export default defineComponent({
    props: {},
    data() {
        return {
            selectedDevice: null as any,
            selectedDevKey: {} as any,
            selectedDev: null as any,
            selectedLog: null as any,
            expandedKeys: {} as any,
            locationTree: [] as any,
            deviceToLocationKeyMap: {} as any,

            queryLogId: -1,
            queryLocDevId: -1,

            loadInterval: null as any,
            initialLoad: true
        };
    },
    mounted() {
        try {
            if ("locationDeviceId" in this.$route.query)
                this.queryLocDevId = parseInt(this.$route.query.locationDeviceId!.toString())

            if ("logId" in this.$route.query)
                this.queryLogId = parseInt(this.$route.query.logId!.toString())
        } catch { }

        this.reinitLoadInterval()
    },
    beforeUnmount() {
        clearInterval(this.loadInterval)
    },
    methods: {
        reinitLoadInterval() {
            clearInterval(this.loadInterval)
            this.loadInterval = setInterval(() => {
                this.getLocationTreeData()
            }, 5000);
            this.getLocationTreeData()
        },
        getLocationTreeData() {
            this.$store.dispatch("locationDevice/getForTree")
                .then((data) => {
                    var locations = {} as { [key: string]: any }
                    var devices = {} as any

                    data.map((l: any) => {
                        if (!(l.locationName in locations))
                            locations[l.locationName] = {
                                key: 'l_' + l.locationId,
                                id: l.locationId,
                                label: l.locationName,
                                children: [],
                                styleClass: this.getDeviceNodeClass(""),
                                status: null,
                                isDevice: false
                            }
                        if (!this.$cx.isNullOrEmpty(l.name)) {
                            let device = {
                                key: 'ld_' + l.id,
                                id: l.id,
                                label: l.name,
                                styleClass: this.getDeviceNodeClass(l.ztpStatus),
                                status: l.ztpStatus,
                                ztpContactTime: l.ztpContactTime,
                                filename: l.filename,
                                serials: l.serials,
                                isDevice: true
                            }
                            locations[l.locationName].children.push(device)
                            this.deviceToLocationKeyMap[device.key] = locations[l.locationName].key
                            devices[device.id] = device
                            if (!this.$cx.isNullOrEmpty(l.ztpStatus)) {
                                locations[l.locationName].styleClass = this.getDeviceNodeClass(l.ztpStatus);
                            }
                        }
                    })
                    this.locationTree = Object.values(locations)

                    if (this.queryLocDevId != -1) {
                        let k = 'ld_' + this.queryLocDevId
                        this.selectedDevKey[k] = true
                        this.selectedDev = devices[this.queryLocDevId];
                        (this.$refs as any)['viewer'].getLogsByLocationDeviceId(this.queryLocDevId)
                        if (this.initialLoad) {
                            //this.expandAll()
                            this.expandedKeys[this.deviceToLocationKeyMap[k]] = true
                            this.initialLoad = false
                        }
                    }
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onLogChanged(ev: any) {
            this.initialLoad = false
            this.queryLogId = ev.id
            this.queryLocDevId = this.selectedDev.id
            this.$router.push({ name: this.$route.name!, query: { locationDeviceId: this.selectedDev.id, logId: ev.id } })
        },
        onLocationDeviceChanged(ev: any) {
            if (ev.isDevice) {
                this.selectedDev = ev;
                this.queryLocDevId = ev.id;
                (this.$refs as any)['viewer'].getLogsByLocationDeviceId(ev.id);
                this.$router.push({ name: this.$route.name!, query: { locationDeviceId: ev.id } });
            }
        },
        expandAll() {
            for (let node of this.locationTree)
                this.expandNode(node);
            this.expandedKeys = { ...this.expandedKeys };
        },
        expandNode(node: any) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;
                for (let child of node.children)
                    this.expandNode(child);
            }
        },
        getDeviceNodeClass(status: string) {
            if (this.$cx.isNullOrEmpty(status)) return 'cx-ztp-node'
            return 'cx-ztp-node cx-ztp-node-' + status.toLowerCase()
        }
    }
});
