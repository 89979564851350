import { api } from "@/_config/api";

const entityName = "log";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getLogsWithFilter(context: any, props: any) {
      try {
        context.state.loading = true;
        let response = await api.get(`logs/filter?id=${props.id}`,
          {
            params: {
              ...{
                page: props.page,
                size: props.size,
              },
              ...props.filters,
            },
          }
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getZTPLogStatus(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/status/ztp`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getLogsByLocationDeviceId(context: any, locationDeviceId: Number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/ztp/${locationDeviceId}`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
