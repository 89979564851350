import { RouteRecordRaw } from "vue-router"

import Rule from "./rule/Rule.vue"
import RuleEditor from "./rule/RuleEditor.vue"

import Compliance from "./Compliance.vue";
import ComplianceEditor from "./ComplianceEditor.vue";
import ComplianceOverview from "./ComplianceOverview.vue";

import DeviceFamilyGroup from "./deviceFamilyGroup/DeviceFamilyGroup.vue"
import DeviceFamilyGroupEditor from "./deviceFamilyGroup/DeviceFamilyGroupEditor.vue"


const complianceRoutes: Array<RouteRecordRaw> = [
    // Compliance
    {
        path: "/compliance-overview",
        name: "compliance-overview",
        component: ComplianceOverview,
        meta: { "menu": "compliance" }
    },
    {
        path: "/compliance",
        name: "compliance",
        component: Compliance,
        meta: { "menu": "compliance" }
    },
    {
        path: "/compliance/new",
        name: "complianceNew",
        component: ComplianceEditor,
        meta: { menu: "compliance" },
    },
    {
        path: "/compliance/:id",
        name: "complianceEditor",
        component: ComplianceEditor,
        meta: { menu: "compliance" },
        props: true,
    },

    // Rule
    {
        path: "/rule",
        name: "rule",
        component: Rule,
        meta: { "menu": "compliance" }
    },
    {
        path: "/rule/new",
        name: "ruleEditorNew",
        component: RuleEditor,
        meta: { menu: "compliance" },
    },
    {
        path: "/rule/:id",
        name: "ruleEditor",
        component: RuleEditor,
        meta: { menu: "compliance" },
        props: true,
    },

    // DeviceFamilyGroup
    {
        path: "/deviceFamilyGroup",
        name: "ruldeviceFamilyGroup",
        component: DeviceFamilyGroup,
        meta: { "menu": "compliance" }
    },
    {
        path: "/deviceFamilyGroup/new",
        name: "deviceFamilyGroupEditorNew",
        component: DeviceFamilyGroupEditor,
        meta: { menu: "compliance" },
    },
    {
        path: "/deviceFamilyGroup/:id",
        name: "deviceFamilyGroupEditor",
        component: DeviceFamilyGroupEditor,
        meta: { menu: "compliance" },
        props: true,
    },
]

export default complianceRoutes