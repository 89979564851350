
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { CiscoMerakiNetworkDto } from "@/types/dto";
import { MerakiNetworkProductType } from "@/types/meraki-product-types";

export default defineComponent({

       setup: () => ({ v$: useVuelidate() as any }),
       validations() {
              return {
                     entity: {
                            name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
                            organizationId: { required, $autoDirty: true },
                            token: { required, $autoDirty: true },
                     },
              };
       },
       data() {
              return {
                     entity: {} as CiscoMerakiNetworkDto,
                     selectedProductTypes: [] as any,
                     productTypes: Object.entries(MerakiNetworkProductType).map(([key, value]) => ({ label: key, value: value })),
                     result: [] as any,
              }
       },

       methods: {
              create() {
                     const templateName = "Meraki_create_network";
                     if (this.selectedProductTypes) {
                            this.entity.productTypes = this.selectedProductTypes;
                     }

                     this.createNetwork(templateName, this.entity)
                            .then(response => this.result = response)
                            .catch(error => {
                                   console.error('There was a problem fetching the data:', error);
                            });
              },

              createNetwork(templateName: string, merakiNetworkDto: any) {
                     return this.$store.dispatch("ciscoMerakiNetwork/create", {
                            templateName: templateName,
                            merakiDto: merakiNetworkDto
                     });
              },
       }

});
