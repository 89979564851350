
import { defineComponent } from "vue";

export default defineComponent({
    props: {},
    data() {
        return {
            searchTerm: "",
        };
    },
    computed: {
        gridItems() {
            let items = [
                /*{
                  label: this.$t("dayN", 'lb'),
                  description: this.$t("dayN", "desc"),
                  icon: "pi pi-clock",
                  to: "/dayn",
                },*/
                {
                    label: this.$t("compliance", 'lb'),
                    description: this.$t("compliance", "desc"),
                    icon: "pi pi-book",
                    to: "/compliance",
                },
                {
                    label: this.$t("deviceFamilyGroup", 'lb'),
                    description: this.$t("deviceFamilyGroup", "desc"),
                    icon: "pi pi-users",
                    to: "/deviceFamilyGroup",
                },
                {
                    label: this.$t("rule", 'lb'),
                    description: this.$t("rule", "desc"),
                    icon: "pi pi-search",
                    to: "/rule",
                }
            ];

            if (this.searchTerm.length > 0) {
                items = items.filter((element) =>
                    JSON.stringify(element)
                        .toLowerCase()
                        .includes(this.searchTerm.toLowerCase())
                );
            }

            return items;
        },
    },
});
