
import { CxTableColumn, CxTableConfig } from "@/types/cx-table-config";
import { CiscoAciDto, CiscoAciQueryRequest } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            apic: {} as CiscoAciDto,
            acis: [] as CiscoAciDto[],
            result: [] as any,
            columns: [] as { [key: string]: string }[]
        };
    },
    created() {
        this.load()
    },
    computed: {
        tableConfig() {
            return new CxTableConfig(this.columns.map(c => new CxTableColumn(c["field"], c["label"])))
        },
        groupedAcis() {
            let groups = {} as any
            this.acis.forEach(aci => {
                if (!(aci.groupRef! in groups))
                    groups[aci.groupRef!] = []
                groups[aci.groupRef!].push(aci)
            })

            return Object.entries(groups).map(g => ({
                label: g[0],
                items: g[1]
            }))
        }
    },
    methods: {
        load() {
            this.$store.dispatch("ciscoAci/getAcis")
                .then((acis: any) => {
                    this.acis = acis
                })
                .catch(error => this.$cx.error(error, this.$t('aci', "errorQueryACI")))
        },
        onRun() {
            this.$store.dispatch("ciscoAci/queryAci", {
                aciId: this.apic.id,
                templateName: 'ACI_Get_Interface_Errors'
            } as CiscoAciQueryRequest)
                .then(r => {
                    let result = JSON.parse(r.replaceAll("'", '"'))
                    this.columns = result.columns
                    this.result = result.data
                })
                .catch(error => this.$cx.error(error, this.$t('aci', "errorQueryACI")))
        }
    },
});
