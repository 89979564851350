import { RouteRecordRaw } from "vue-router"

import Manufacturer from "./Manufacturer.vue";
import ManufacturerEditor from "./ManufacturerEditor.vue";
import ContactTypes from "./contact/ContactType.vue";
import ContactTypeEditor from "./contact/ContactTypeEditor.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/manufacturer",
        name: "manufacturer",
        component: Manufacturer,
        meta: { menu: "management" }
    },
    {
        path: "/manufacturer/new",
        name: "manufacturerEditorNew",
        component: ManufacturerEditor,
        meta: { menu: "management" }
    },
    {
        path: "/manufacturer/:id",
        name: "manufacturerEditor",
        component: ManufacturerEditor,
        meta: { menu: "management" },
        props: true,
    },
    {
        path: "/contactType",
        name: "contactType",
        component: ContactTypes,
        meta: { menu: "management" },
    },
    {
        path: "/contactType/new",
        name: "contactTypeEditorNew",
        component: ContactTypeEditor,
        meta: { menu: "management" },
    },
    {
        path: "/contactType/:id",
        name: "contactTypeEditor",
        component: ContactTypeEditor,
        meta: { menu: "management" },
        props: true,
    }
]

export default routes