import { LoginProfileDto, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";

class LoginProfileStore extends CxBaseStoreModule<LoginProfileDto> {

    constructor() {
        super("loginProfile")
        this.state.entityType = CxEntityType.LOGIN_PROFILE
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
        })
    }
}

export default new LoginProfileStore().createStore()
