import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "flex mb-2" }
const _hoisted_4 = { class: "ml-2 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.location.catalystCenterActivated,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.location.catalystCenterActivated) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('catalystCenter', 'activated')), 1)
        ]),
        (_ctx.location.catalystCenterActivated)
          ? (_openBlock(), _createBlock(_component_cx_label, {
              key: 0,
              label: _ctx.$t('catalystCenter', 'apiParam_lb')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cx_dropdown, {
                  modelValue: _ctx.location.catalystCenter,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.location.catalystCenter) = $event)),
                  store: "apiParam",
                  optionLabel: "name",
                  placeholder: _ctx.$t('catalystCenter', 'apiParam_ph'),
                  filters: {
                                type: _ctx.catalystCenterType
                            },
                  dropdownOptions: {
    route: 'apiParamEditor',
    getId: (en) => en?.id,
    editable: true
},
                  disabled: !_ctx.isEditing
                }, null, 8, ["modelValue", "placeholder", "filters", "dropdownOptions", "disabled"])
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}