
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ContactDto, DeviceFamilyDto, ManufacturerDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
      },
    };
  },
  data() {
    return {
      contacts: [] as ContactDto[],
      entity: {} as ManufacturerDto,
      deviceFamilyData: [] as DeviceFamilyDto[],
      initialState: {} as ManufacturerDto,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    deviceFamilyTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    contactTableConfig() {
      return new CxTableConfig([
        new CxTableColumn(
          "contactType",
          this.$t("contactType", 'lb'),
          CxDataType.GetType
        ),
        new CxTableColumn("name", "name", CxDataType.Custom),
        new CxTableColumn("company", this.$c("company")),
        new CxTableColumn("contact", "contact", CxDataType.Custom),
        new CxTableColumn("address", this.$c("address")),
        new CxTableColumn("country", this.$c("country")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("firstName", this.$c("firstName")),
        new CxTableColumn("lastName", this.$c("lastName")),
        new CxTableColumn("address", this.$c("address")),
        new CxTableColumn("country", this.$c("country")),
        new CxTableColumn("company", this.$c("company")),
        new CxTableColumn("email", this.$c("email")),
        new CxTableColumn("phoneNumber", this.$c("phone")),
        new CxTableColumn("mobilePhoneNumber", this.$c("mobile")),
        new CxTableColumn("contactType", "", CxDataType.Custom),
        new CxTableColumn("manufacturer", "", CxDataType.Custom),
      ])
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("manufacturer/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

        this.loadContacts();
        this.loadDeviceFamilies();
      }
    },
    loadDeviceFamilies() {
      this.$store
        .dispatch("manufacturer/getFamilies", this.id)
        .then((deviceFamilies) => {
          this.deviceFamilyData = deviceFamilies;
        });
    },
    loadContacts() {
      this.$store
        .dispatch("manufacturer/getContacts", this.id)
        .then((contacts) => {
          this.contacts = contacts;
        });
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("manufacturer/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("manufacturer", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("manufacturer")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("manufacturer/create", this.entity)
          .then((newEntity: ManufacturerDto) => {
            this.$cx.notifyCreated(this.$t("manufacturer", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("manufacturer")
            else
              this.$cx.goToById("manufacturerEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
  },
});
