enum CxPermission {
    NONE = 0,
    ROLE = 1,
    DEVICE_MODEL = 2,
    MANUFACTURER = 3,
    CONTACT = 4,
    CONTACT_TYPE = 5,
    WORKER_TEMPLATE = 6,
    VARIABLE = 7,
    VARIABLE_ORDER = 8,
    VARIABLE_VALUE = 9,
    OS = 10,
    OS_IMAGE = 11,
    PORT_USE = 12,
    PORT_TYPE = 13,
    LOCATION = 14,
    LOCATION_JOB = 15,
    LOCATION_DEVICE = 16,
    LOCATION_DEVICE_ACCESS = 17,
    LOCATION_DEVICE_CONFIG = 18,
    LOCATION_TYPE = 19,
    LOCATION_CATEGORY = 20,
    LOCATION_CATEGORY_ROLE = 21,
    LOCATION_CATEGORY_MAPPING = 22,
    SCHEDULER_JOB = 23,
    DEVICE_TEMPLATE = 24,
    DEVICE_TEMPLATE_PORT = 25,
    DEVICE_ROLE = 26,
    DEVICE_ROLE_PORT_USE = 27,
    DEVICE_FAMILY = 28,
    VIRTUAL_NETWORK = 29,
    IP_POOL = 30,
    IP_POOL_SUBNET = 31,
    CONFIGURATION = 32,
    LOGIN_PROFILE = 33,
    CODE_TEMPLATE = 34,
    SCHEDULE_OUTPUT = 35,
    LOGICAL_INTERFACE = 36,
    LOCATION_DEVICE_IP = 37,
    APP_CONTEXT = 38,
    JOB = 39,
    API_PARAM = 40
}

enum CxPermissionLevel {
    NONE = "A",
    READ = "B",
    WRITE = "C",
    APPROVAL = "D"
}

interface CxGrant {
    perm: CxPermission,
    lvl: CxPermissionLevel
}

const CxDefaultPermissions = 'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA'

export { CxPermission, CxPermissionLevel, CxGrant, CxDefaultPermissions }