
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { OSDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: {
          required,
          maxLength: maxLength(vMaxLen.name),
          $autoDirty: true,
        },
      },
    };
  },
  data() {
    return {
      entity: {} as OSDto,
      initialState: {} as OSDto,
      uploadError: null as any,
      uploadSuccess: false
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("os/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      }
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("os/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("os", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack) this.$cx.goTo("os");
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.uploadError = null
        this.uploadSuccess = false
        let files = (this.$refs as any)["files"].files;
        this.$store
          .dispatch("os/create", {
            entity: this.entity,
            files: files
          })
          .then((newEntity: OSDto) => {
            this.$cx.notifyCreated(this.$t("os", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            if (files.length > 0)
              this.uploadSuccess = true
            if (goBack) this.$cx.goTo("os");
            else this.$cx.goToById("osEditor", newEntity.id!);
          })
          .catch((error) => {
            this.uploadError = error
            this.$cx.error(error, this.$cx.e("creating"))
          });
      }
    },
    onUploadFile() {
      this.uploadError = null
      this.uploadSuccess = false
      let files = (this.$refs as any)["files"].files;
      this.$store.dispatch("os/uploadFiles", {
        id: this.id,
        files: files
      })
        .then(() => {
          this.$cx.notifyEditorUploaded(this.$t("os", "lb"));
          this.load();
          this.uploadSuccess = true
        })
        .catch((error) => {
          console.log(error)
          this.uploadError = error
          this.$cx.error(error, this.$cx.e("uploading"))
        });
    },
    onUploadFileMultiple() {
      this.uploadError = null
      this.uploadSuccess = false
      let files = (this.$refs as any)["files"].files;
      this.$store.dispatch("os/uploadImageFiles", {
        id: this.id,
        files: files
      })
        .then(() => {
          this.$cx.notifyEditorUploaded(this.$t("os", "lb"));
          this.load();
          this.uploadSuccess = true
        })
        .catch((error) => {
          console.log(error)
          this.uploadError = error
          this.$cx.error(error, this.$cx.e("uploading"))
        });
    },
    onDownloadImageFile(data: any) {
      this.$cx.success("Download wird gestartet", "")
      this.$store.dispatch("os/downloadImage", { id: this.id, filename: data.filename })
    },
    onRemoveImageFile(data: any, index: number) {
      this.$cx.confirmDelete(() => {
        this.$cx.removeFrom(this.entity.osImages as any[], data.filename, "filename")
      })
    },
    onDownloadFile() {
      this.$store.dispatch("os/download", this.id)
    },
    onClearFiles() {
      (this.$refs as any)["files"].clear()
    }
  },
});
