
import { ApiParamDto, CxApiType, Location, CatalystCenterDto } from "@/types/dto";
import { PropType, defineComponent } from "vue";

export default defineComponent({
    props: {
        locationId: {
            type: Number
        },
        location: {
            required: true,
            type: Object as PropType<Location>
        }
    },
    data() {
        return {
            site: {} as any,
            siteType: null as any,
            floorType: {} as any,
            parentSite: {} as any,
            createNewSite: false,
            sitesList: [] as any[],
            activated: false,
            apiParam: null as ApiParamDto | null,
            catalystCenterType: CxApiType.CATALYST_CENTER
        };
    },
    watch: {
        locationId: function (v) {
            if (this.locationId == null) return
            this.$store.dispatch('catalystCenter/getLocationStatus', this.locationId)
                .then((data: CatalystCenterDto) => {
                    console.log(data)
                })
        },
        apiParam: function (v) {
            if (this.apiParam == null) return
            if (this.apiParam.id == null) return
            this.$store.dispatch("catalystCenter/getSites", this.apiParam.id)
                .then((data) => {
                    this.sitesList = [];
                    for (var item of data) {
                        item.type = "site"
                        if (item.additionalInfo != null && item.additionalInfo.length > 0) {
                            let attr = item.additionalInfo.find((s: any) => s.nameSpace == 'Location')
                            if (attr != null && "attributes" in attr)
                                if ("type" in attr.attributes)
                                    item.type = attr.attributes.type
                        }
                        this.sitesList.push(item)
                    }
                })
        }
    },
    computed: {
        isEditing() {
            return this.locationId != null;
        },
        parentSiteList() {
            if (this.siteType == 'building')
                return this.sitesList.filter(s => s.type != 'floor' && s.type != 'building')
            if (this.siteType == 'floor')
                return this.sitesList.filter(s => s.type == 'building')
            return this.sitesList.filter(s => s.type != 'floor')
        }
    },
    methods: {
    }
});
