
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType, LocationDto } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    acceptedFileType: {
      type: String,
      default: ".csv",
    },
  },
  data() {
    return {
      locations: {} as any,
      displayImport: false
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("address", this.$c("address"), CxDataType.Text),
        new CxTableColumn("country", this.$c("country")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    importTableConfig(): CxTableConfig {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("continent", this.$c("continent")),
        new CxTableColumn("country", this.$c("country")),
        new CxTableColumn("state", this.$c("state")),
        new CxTableColumn("postCode", this.$c("postCode")),
        new CxTableColumn("city", this.$c("city")),
        new CxTableColumn("street", this.$c("street")),
        new CxTableColumn("streetNumber", this.$c("streetNumber")),
        new CxTableColumn("locationCategory", this.$t('locationCategory', 'lb'), CxDataType.GetName),
        new CxTableColumn("locationTypes", this.$t('locationType', 'lb'), CxDataType.GetNameFromArray)
      ])
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("location/getWithFilter", filters)
        .then((locations) => {
          this.locations = locations;
          this.locations.content = this.locations.content.map(
            (l: LocationDto) => ({
              ...l,
              ...{
                address: this.getAddressLabel(l),
              },
            })
          );
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("location/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      (<any>this.$refs)['confirmDelete'].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("location", "lb"))
        this.load((this.$refs as any)["tbl"].tableFilter)
      });
    },
    onClearFilters() {
      (<any>this.$refs)["tbl"].clearFilters();
    },
    getAddressLabel(l: LocationDto) {
      let s = `${l.street!} ${l.streetNumber!}\n`;
      s += `${l.postCode!} ${l.city!}\n`;

      if (l.state != null) s += l.state + " ";
      if (l.country != null) s += l.country;
      return s;
    },
    onReload() {
      (<any>this.$refs)["tbl"].reload();
    }
  },
});
