import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.deviceFamily.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'deviceFamily'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('deviceFamily')))
      }, null, 8, ["isEditing", "onSave"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('manufacturer', 'lb'),
          v$: _ctx.v$.entity.manufacturer
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_dropdown, {
              modelValue: _ctx.entity.manufacturer,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.manufacturer) = $event)),
              store: "manufacturer",
              optionLabel: "name",
              placeholder: _ctx.$t('manufacturer', 'ph'),
              dropdownOptions: {
            route: 'manufacturerEditor',
            getId: () => _ctx.entity.manufacturer?.id,
            editable: true
          }
            }, null, 8, ["modelValue", "placeholder", "dropdownOptions"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}