
import { DeviceFamilyDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";

export default defineComponent({
  props: {
    editorApi: {
      type: Object as PropType<CxFormApi>,
    },
    manufacturerId: String,
  },
  data() {
    return {
      id: -1 as number,
      entity: {} as DeviceFamilyDto,
    };
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
      },
    };
  },
  computed: {
    isEditing() {
      return this.id > 0;
    },
  },
  created() {
    this.editorApi!.onSave = async (closeFn: any) => {
      if (await this.$cx.notifyValidationError(this.v$)) return false;

      if (this.isEditing) {
        this.$store
          .dispatch("deviceFamily/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("deviceFamily", "lb"));
            closeFn();
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.entity.manufacturer = { id: parseInt(this.manufacturerId!) };
        this.$store
          .dispatch("deviceFamily/create", this.entity)
          .then(() => {
            this.$cx.notifyCreated(this.$t("deviceFamily", "lb"));
            closeFn();
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    };
    this.editorApi!.load = (id: number) => {
      this.id = id;
      this.$store.dispatch("deviceFamily/getById", id).then((entity) => {
        this.entity = entity;
      });
    };
    this.editorApi!.delete = (id: number) => {
      this.$store.dispatch("deviceFamily/delete", id).then(() => {
        this.$cx.notifyDeleted(this.$t("deviceFamily", "lb"));
      });
    };
    this.editorApi!.reset = () => {
      this.entity = {} as DeviceFamilyDto;
    };
    this.editorApi!.isEditing = () => {
      return this.id > 0;
    };
  },
});
