
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      globalConfigurations: []
    };
  },
  computed: {
    globalConfigurationTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn(
          "manufacturer",
          this.$t("manufacturer", 'lb'),
          CxDataType.GetName
        ),
        new CxTableColumn(
          "roles",
          this.$t("deviceRole", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "locations",
          this.$t("location", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "locationTypes",
          this.$t("locationType", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "locationCategories",
          this.$t("locationCategory", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "models",
          this.$t("deviceModel", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "families",
          this.$t("deviceFamily", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn(
          "templates",
          this.$t("deviceTemplate", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn("weight", this.$c("weight")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("configuration/getWithFilter", filters)
        .then((configs) => {
          this.globalConfigurations = configs;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onDelete(data: any) {
      this.$cx.confirmDeleteDetail(
        data.name,
        () => {
          this.$store
            .dispatch("configuration/delete", data.id)
            .then(() => {
              this.$cx.notifyDeleted(this.$t("configuration", "lb"));
              (<any>this.$refs)['config-tbl'].reload()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    },
    onClearFilters() {
      (<any>this.$refs)['config-tbl'].clearFilters()
    }
  },
});
