
import { CxTableConfig } from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    store: {
      type: String,
      default: "",
    },
    importTableConfig: {
      type: CxTableConfig,
    },
    v2: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items(): any[] {
      return [
        {
          label: this.$t('import', 'template'),
          icon: "pi pi-download",
          command: () => {
            this.downloadImportTemplate();
          },
        },
      ]
    }
  },
  data() {
    return {
      displayImport: false
    };
  },
  methods: {
    exportData() {
      this.$store.dispatch(this.store + "/getExport");
    },
    importData() {
      this.displayImport = true;
      (this.$refs as any)["importDlg"].onShow()
    },
    downloadImportTemplate() {
      this.$store.dispatch(this.store + "/getImportTemplate");
    },
  },
});
