import { AxiosResponse } from "axios";
import { api } from "../_config/api";
import { CxExportType } from "./dto";

export default class CxApi<T> {
  entityName: string;

  constructor(entityName: string) {
    this.entityName = entityName;
  }

  getAll(): Promise<AxiosResponse<T[]>> {
    return api.get<any, AxiosResponse<T[]>>(this.entityName);
  }

  getById(id: number): Promise<AxiosResponse<T>> {
    return api.get<any, AxiosResponse<T>>(this.entityName + "/" + id);
  }

  save(entity: T): Promise<AxiosResponse<T[]>> {
    return api.put<any, AxiosResponse<T[]>>(
      this.entityName + "/" + (<any>entity).id,
      entity
    );
  }

  saveAll(entities: T[]): Promise<AxiosResponse<T[]>> {
    return api.put<any, AxiosResponse<T[]>>(
      this.entityName + "/saveAll",
      entities
    );
  }

  create(entity: T): Promise<AxiosResponse<T[]>> {
    return api.post<any, AxiosResponse<T[]>>(this.entityName, entity);
  }

  delete(id: number): Promise<AxiosResponse<T[]>> {
    return api.delete<any, AxiosResponse<T[]>>(this.entityName + "/" + id);
  }

  export(type: CxExportType): Promise<AxiosResponse<any>> {
    return new Promise(async (resolve, reject) => {
      api
        .get<any, AxiosResponse<any>>(this.entityName + "/export?type=" + type)
        .then((response) => {
          let attachment =
            response.headers["content-disposition"] ||
            response.headers["Content-Disposition"];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
            attachment!
          )![0];
          filename = filename.split("=")[1].replaceAll('"', "");
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export interface ApiIdParams<T> {
  id: number;
  entity: T;
}
