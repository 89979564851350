
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      searchTerm: "",
    };
  },
  computed: {
    gridItems() {
      let items = [
        /*{
          label: this.$t("dayN", 'lb'),
          description: this.$t("dayN", "desc"),
          icon: "pi pi-clock",
          to: "/dayn",
        },*/
        {
          label: this.$t("job", 'lb'),
          description: this.$t("job", "desc"),
          icon: "pi pi-clock",
          to: "/job",
        },
        {
          label: this.$t("codeTemplate", 'lb'),
          description: this.$t("codeTemplate", "desc"),
          icon: "pi pi-bolt",
          to: "/codeTemplate",
        },
        {
          label: this.$t("schedule", 'lb'),
          description: this.$t("schedule", "desc"),
          icon: "pi pi-clock",
          to: "/schedule",
        }
      ];

      if (this.searchTerm.length > 0) {
        items = items.filter((element) =>
          JSON.stringify(element)
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        );
      }

      return items;
    },
  },
});
