import { CxEntityType, DeviceFamilyGroupDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class DeviceFamilyGroupStore extends CxBaseStoreModule<DeviceFamilyGroupDto> {

    constructor() {
        super("deviceFamilyGroup")
        this.state.entityType = CxEntityType.DEVICE_FAMILY_GROUP
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({

        })
    }
}

export default new DeviceFamilyGroupStore().createStore()