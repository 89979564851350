import { api } from "@/_config/api";
import { CodeTemplateDto, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";

class CodeTemplateStore extends CxBaseStoreModule<CodeTemplateDto> {

    constructor() {
        super("codeTemplate")
        this.state.entityType = CxEntityType.CODE_TEMPLATE
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getAllByType: async (context: any, props: any) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/types/${props.type}`, {
                        params: {
                            search: props.search
                        }
                    });
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            getByName: async (context: any, name: any) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/search/${name}`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    return Promise.reject(error);
                }
            },
            run: async (context: any, params: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(`${context.state.entityName}/run`, params);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            calculateTemplate: async (context: any, params: any) => {
                try {
                    let response = await api.get(`${context.state.entityName}/${params.templateId}/compiled/${params.deviceId}`);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            }
        })
    }
}

export default new CodeTemplateStore().createStore()