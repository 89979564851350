
import { CodeTemplateDto, CxCodeTemplateType, CxEntityType } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ApprovalState } from "@/types/approval-state";
import { codeTemplateScopeParameters } from "@/_config/config-scope-parameters";

export default defineComponent({
    props: {
        editorApi: {
            type: Object as PropType<CxFormApi>,
        },
        approvalState: {
            type: Object as PropType<ApprovalState>,
            default: new ApprovalState(CxEntityType.GLOBAL)
        }
    },
    data() {
        return {
            id: -1 as number,
            entity: {} as CodeTemplateDto,
            typeOptions: this.$cx.mapEnumToOptions(CxCodeTemplateType),
            initialEntity: {} as CodeTemplateDto,
            variableGraph: [...codeTemplateScopeParameters]
        };
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
                type: { required, maxLength: maxLength(vMaxLen.text), $autoDirty: true },
                code: { required, $autoDirty: true },
            },
        };
    },
    watch: {
        entity: {
            handler(updatedEntity) {
                this.entity = updatedEntity;
            },
            deep: true
        }
    },
    computed: {
        isEditing() {
            return this.id > 0;
        },
    },
    mounted() {
        this.$store.dispatch("configuration/getVariables")
            .then(variables => {
                this.variableGraph.push({
                    key: 'variable',
                    label: 'variable',
                    children: variables.map((v: string) => ({
                        key: this.$cx.createUid(),
                        label: v
                    }))
                })
            })
    },
    created() {
        this.editorApi!.onSave = async (closeFn: any) => {
            if (await this.$cx.notifyValidationError(this.v$)) return false;

            if (this.isEditing) {
                this.$store
                    .dispatch("codeTemplate/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("codeTemplate", "lb"));
                        this.initialEntity = this.$cx.getState(this.entity);
                        closeFn();
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.$store
                    .dispatch("codeTemplate/create", this.entity)
                    .then((data) => {
                        this.$cx.notifyCreated(this.$t("codeTemplate", "lb"));
                        this.editorApi!.load(data.id);
                        this.initialEntity = this.$cx.getState(this.entity);
                        closeFn(data)
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        };
        this.editorApi!.load = (id: number) => {
            this.id = id;
            if (!this.isEditing) return
            this.$store.dispatch("codeTemplate/getById", id).then((entity) => {
                this.entity = entity;
                this.initialEntity = this.$cx.getState(this.entity);
                this.$emit("entity", entity, this.initialEntity)
            });
        };
        this.editorApi!.delete = (id: number) => {
            this.$store.dispatch("codeTemplate/delete", id).then(() => {
                this.$cx.notifyDeleted(this.$t("codeTemplate", "lb"));
            });
        };
        this.editorApi!.notifyUnsavedChanges = (next: any) => {
            this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
        }
        this.editorApi!.reset = () => {
            this.entity = {} as CodeTemplateDto;
        };
        this.editorApi!.isEditing = () => this.id > 0;
    },
});
