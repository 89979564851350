
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { JobDto, CxEntityType, CxApprovalState, CxJobType, CxJobStatus, CxJobOutput, CxJobResult, IdNameDto, ApprovalDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
    props: {
        id: String,
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                name: {
                    required,
                    maxLength: maxLength(vMaxLen.name),
                    $autoDirty: true,
                },
                type: { required, $autoDirty: true },
                locationDevice: { required, $autoDirty: true },
                codeTemplate: { required, $autoDirty: true }
            },
        };
    },
    data() {
        return {
            entity: {
                status: CxJobStatus.CREATED,
                output: {
                    result: CxJobResult.NONE
                } as CxJobOutput
            } as JobDto,
            initialEntity: {
                status: CxJobStatus.CREATED,
                output: {
                    result: CxJobResult.NONE
                } as CxJobOutput
            } as JobDto,
            jobTypeOptions: this.$cx.mapEnumToOptions(CxJobType),
            jobStatusOptions: this.$cx.mapEnumToOptions(CxJobStatus),
            jobResultOptions: this.$cx.mapEnumToOptions(CxJobResult),
            approvalState: new ApprovalState(CxEntityType.JOB),
            locationDeviceOptions: [] as IdNameDto[],
            loadingLocDevs: false,
            loading: false,
            jobRefreshInterval: null as any,
            autoScroll: true,
            autoScrollInterval: null as any
        };
    },
    mounted() {
        this.load();
        this.initStats()
    },
    beforeUnmount() {
        clearInterval(this.jobRefreshInterval)
        clearInterval(this.autoScrollInterval)
    },
    watch: {
        id: function () {
            this.load();
        }
    },
    computed: {
        isEditing() {
            return this.id != null;
        },
        isSaveVisible() {
            if (this.runJobDisabled) return false
            if (this.entity.schedule != null) return false
            return true
        },
        editDisabled() {
            return !this.approvalState.formEnabled || this.runJobDisabled
        },
        runJobDisabled() {
            return this.entity.status != CxJobStatus.CREATED
        },
        runJobLoading() {
            return this.entity.status == CxJobStatus.PENDING
                || this.entity.status == CxJobStatus.PROCESSING
        },
        runJobLabel() {
            if (this.entity.status == CxJobStatus.PENDING)
                return this.$t('job', 'pending')
            if (this.entity.status == CxJobStatus.PROCESSING)
                return this.$t('job', 'processing')
            return this.$t('job', 'run')
        },
        isRestartVisible() {
            console.log(this.entity.schedule != null, this.entity.status == CxJobStatus.DONE)
            if (this.entity.schedule != null) return false
            if (this.entity.status == CxJobStatus.DONE) return true
            return false
        },
        isApproval() {
            return this.$cx.isApprovalRoute(this.$router)
        }
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
    },
    methods: {
        load() {
            if (this.isEditing) {
                this.approvalState.setStore("job", this.id!)

                if (this.isApproval) {
                    this.approvalState.setStore("job", this.id!)
                    this.approvalState.fetchNewDataset().then((data: ApprovalDto) => {
                        this.entity = data.data
                        this.initialEntity = this.$cx.getState(this.entity)
                    })
                } else {
                    this.loading = true
                    this.$store
                        .dispatch("job/getById", this.id)
                        .then((entity) => {
                            this.entity = entity;
                            this.initialEntity = this.$cx.getState(this.entity);
                            this.scrollOutput()
                        })
                        .catch((error) => this.$cx.error(error, this.$cx.e("loading")))
                        .finally(() => this.loading = false)

                    this.approvalState.fetchApprovalData()
                }
            }
        },
        reloadJob() {
            if (this.isEditing) {
                this.$store
                    .dispatch("job/getById", this.id)
                    .then((entity) => {
                        this.entity.status = entity.status
                        this.entity.output = entity.output
                        this.entity.workerLog = entity.workerLog
                    })
            }
        },
        initStats() {
            clearInterval(this.jobRefreshInterval)
            this.jobRefreshInterval = setInterval(() => {
                this.reloadJob()
            }, 2000)
            this.reloadJob()
        },
        scrollOutput() {
            this.$nextTick(() => {
                [1, 2, 3, 4].forEach(k => {
                    let el1 = document.getElementById('job-out-' + k) as any
                    if (el1 != null)
                        el1.scrollTop = el1.scrollHeight;
                })
            })
        },
        async save(goBack: Boolean) {
            if (await this.$cx.notifyValidationError(this.v$)) return false;

            if (this.isEditing) {
                this.$store
                    .dispatch("job/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("job", "lb"));
                        this.initialEntity = this.$cx.getState(this.entity);
                        if (goBack)
                            this.$cx.goTo("job")
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.$store
                    .dispatch("job/create", this.entity)
                    .then((newEntity: JobDto) => {
                        this.$cx.notifyCreated(this.$t("job", "lb"));
                        this.entity = newEntity;
                        this.initialEntity = this.$cx.getState(this.entity);
                        if (goBack)
                            this.$cx.goTo("job")
                        else
                            this.$cx.goToById("jobEditor", newEntity.id!);
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        },
        runJob() {
            this.loading = true
            this.$store.dispatch("job/run", this.entity.id!).then(() => {
                this.reloadJob()
                this.$cx.success(this.$t('job', 'runTitle'), this.$t('job', 'runText'))
            }).catch((error) => this.$cx.error(error, this.$cx.t("job", 'errorRun')))
                .finally(() => {
                    this.loading = false
                })
        },
        onApprovalViewChanged(data: any, isViewingChanges: boolean) {
            if (isViewingChanges) {
                (this.$refs as any)['approval'].apply(this.entity, data)
            } else {
                (this.$refs as any)['approval'].apply(this.entity, this.initialEntity)
            }
        },

        onApprovalChanged(state: CxApprovalState, id: string) {
            if (state == CxApprovalState.APPROVED) {
                this.$cx.goToById("jobEditor", parseInt(id))
                return
            }

            if (state == CxApprovalState.REJECTED && this.isApproval) {
                this.$cx.goTo("job")
                return
            }

            if (!this.isEditing) {
                this.$cx.goToApproval("jobEditor", id)
                return
            }

            (this.$refs as any)['approval'].reset()
            this.load()
        },
        getLines(text: any) {
            if (text == null) return ""
            if (this.$cx.isNullOrEmpty(text.trim()))
                return ""
            return ` (${text.split('\n').length})`
        },
        onAutoScrollChanged() {
            clearInterval(this.autoScrollInterval)
            if (this.autoScroll)
                this.autoScrollInterval = setInterval(() => {
                    this.scrollOutput()
                }, 500)
        },
        onRestartJob() {
            this.$store.dispatch("job/restart", this.entity.id)
                .then(() => {
                    this.$cx.success(this.$t('job', 'restarted'), '')
                    this.initStats()
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("error")));
        },
    },
});
