
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      schedules: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("description", this.$c("description")),
        new CxTableColumn("jobType", this.$c("type")),
        new CxTableColumn("state", this.$c('status')),
        new CxTableColumn("activated", this.$t('schedule', 'activated'), CxDataType.Checkbox),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("schedule/getWithFilter", filters)
        .then((schedules) => {
          this.schedules = schedules;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("schedule/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      this.$cx.confirmDeleteDetail(
        data.name,
        () => {
          this.$store
            .dispatch("schedule/delete", data.id)
            .then(() => {
              this.$cx.notifyDeleted(this.$t("schedule", "lb"));
              (<any>this.$refs)['tbl'].reload()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
