
import { CxTableColumn, CxTableConfig } from "@/types/cx-table-config";
import { CiscoAciDto, CiscoAciQueryRequest } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            apic: {} as CiscoAciDto,
            acis: [] as CiscoAciDto[],

            pod: {} as any,
            pods: [] as any[],

            node: {} as any,
            nodes: [] as any[],

            interface: {} as any,
            interfaces: [] as any[],

            text: ""
        };
    },
    created() {
        this.load()
    },
    computed: {
        groupedAcis() {
            let groups = {} as any
            this.acis.forEach(aci => {
                if (!(aci.groupRef! in groups))
                    groups[aci.groupRef!] = []
                groups[aci.groupRef!].push(aci)
            })

            return Object.entries(groups).map(g => ({
                label: g[0],
                items: g[1]
            }))
        }
    },
    methods: {
        onApicSelected() {
            this.pod = null
            this.pods.length = 0
            this.node = null
            this.nodes.length = 0
            this.interface = null
            this.interfaces.length = 0
            this.text = ""
            this.onGetPods()
        },
        onPodSelected() {
            this.node = null
            this.nodes.length = 0
            this.interface = null
            this.interfaces.length = 0
            this.text = ""
            this.onGetNodes()
        },
        onNodeSelected() {
            this.interface = null
            this.interfaces.length = 0
            this.text = ""
            this.onGetInterfaces()
        },
        onInterfaceSelected() {
            this.$store.dispatch("ciscoAci/queryAci", {
                aciId: this.apic.id,
                templateName: 'ACI_Get_Interface_EPGs',
                props: {
                    pod: this.pod.name,
                    node: this.node.name,
                    interface: this.interface.name
                }
            } as CiscoAciQueryRequest)
                .then(r => {
                    let result = JSON.parse(r.replaceAll("'", '"'))
                    this.text = result.data
                })

        },
        load() {
            this.$store.dispatch("ciscoAci/getAcis")
                .then((acis: any) => {
                    this.acis = acis
                })
        },
        onGetPods() {
            this.$store.dispatch("ciscoAci/queryAci", {
                aciId: this.apic.id,
                templateName: 'ACI_Get_Pods'
            } as CiscoAciQueryRequest)
                .then(r => {
                    let result = JSON.parse(r.replaceAll("'", '"'))
                    this.pods = result.data
                })
        },
        onGetNodes() {
            this.$store.dispatch("ciscoAci/queryAci", {
                aciId: this.apic.id,
                templateName: 'ACI_Get_Nodes',
                props: {
                    pod: this.pod.name
                }
            } as CiscoAciQueryRequest)
                .then(r => {
                    let result = JSON.parse(r.replaceAll("'", '"'))
                    this.nodes = result.data
                })
        },
        onGetInterfaces() {
            this.$store.dispatch("ciscoAci/queryAci", {
                aciId: this.apic.id,
                templateName: 'ACI_Get_Interfaces',
                props: {
                    pod: this.pod.name,
                    node: this.node.name
                }
            } as CiscoAciQueryRequest)
                .then(r => {
                    let result = JSON.parse(r.replaceAll("'", '"'))
                    this.interfaces = result.data
                })
        }
    },
});
