
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        headers: {
            type: Array as PropType<Array<any>>,
            default: []
        },
        stateKey: {
            type: String,
            default: ""
        },
        isEditing: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            index: 0,
            initialState: null as any
        };
    },
    created() {
        if (!this.isEditing) return
        let state = this.$cx.getStorage("cx_tb_" + this.stateKey, "")
        if (!this.$cx.isNullOrEmpty(state)) {
            this.initialState = JSON.parse(state)
            this.index = this.initialState.index
            this.$emit("onTabChanged", { index: this.index })
        }
    },
    methods: {
        onTabChanged(ev: any) {
            this.$emit("onTabChanged", ev)
            this.initialState = {
                index: ev.index
            }
            if (!this.$cx.isNullOrEmpty(this.stateKey))
                localStorage.setItem("cx_tb_" + this.stateKey, JSON.stringify(this.initialState))
        }
    },
});
