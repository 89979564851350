
import { defineComponent, PropType } from "vue";
import { CxFormApi } from "@/types/cx-form-api";
import { CxTableConfig } from "../types/cx-table-config";
import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";
export default defineComponent({
  props: {
    config: {
      type: CxTableConfig,
    },
    data: {
      type: Object,
    },
    grant: {
      type: Object as PropType<CxGrant>,
      default: {
        perm: CxPermission.NONE,
        lvl: CxPermissionLevel.NONE
      } as CxGrant
    },
    title: String,
    loading: Boolean,
    entityName: String,
    deleteFn: Function as PropType<(id:number)=>Promise<any>>,
    deleteDlgFn: Function as PropType<(id: number, callback: Function) => Promise<any>>,
    tableStyle: {
      type: String,
      default: ""
    },
    stateKey: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dialogVisible: false,
      editorApi: {} as CxFormApi,
      tableId: this.$cx.createUid()
    };
  },
  computed: {
    isEditing() {
      if (this.editorApi == null) return false
      if (this.editorApi.isEditing == null) return false
      return this.editorApi.isEditing()
    },
    disabled() {
      //if (this.loading) return true
      if (this.grant == null) return false
      if (this.grant.perm == CxPermission.NONE) return false
      return !this.$auth.permits(this.grant.perm, this.$lvl.READ)
    },
    disableWrite() {
      //if (this.loading) return true
      if (this.grant == null) return false
      if (this.grant.perm == CxPermission.NONE) return false
      return !this.$auth.permits(this.grant.perm, this.$lvl.WRITE)
    },
    cssEditIcon() {
      if (this.grant == null) return 'pi-plus'
      if (this.$auth.permits(this.grant.perm, this.grant.lvl))
        return 'pi pi-plus'
      return 'pi pi-lock'
    },
    filters() {
      return (<any>this.$refs)[this.tableId].tableFilter
    }
  },
  methods: {
    onOpenEdit(id: number) {
      this.dialogVisible = true;
      this.$nextTick(() => this.editorApi.load(id));
    },
    onOpenCreate() {
      this.dialogVisible = true;
      this.$nextTick(() => this.editorApi.reset());
    },


    onOpenDelete(id: number)
    {
      const deletionCallback = () => {
        this.$cx.notifyDeleted(this.$t(this.entityName!, 'lb'))
        this.$emit("load", this.filters)
      }

      if (this.deleteDlgFn)
        this.deleteDlgFn(id, deletionCallback)

      else
        this.$cx.confirmDelete(() => {
          if (this.deleteFn != null) {
            this.deleteFn(id).then(deletionCallback)
            return
          }
          this.$store.dispatch(this.entityName + "/delete", id).then(deletionCallback)
          .catch((error) => this.$cx.error(error, this.$cx.e("deleting")))
        })
    },


    onCancel() {
      this.dialogVisible = false;
      this.$emit("load", this.filters);
    },
    onSave() {
      this.editorApi.onSave(() => {
        this.dialogVisible = false;
        this.$emit("load", this.filters);
      });
    },
    onFilterChanged(filters: any) {
      this.$emit("load", this.filters)
    },
    onClearFilters() {
      (<any>this.$refs)[this.tableId].clearFilters()
    }
  },
});
