import { api } from "@/_config/api";
import CxBaseStoreModule from "@/types/cx-store-module";
import { CxEntityType, JobDto } from "@/types/dto";

class CodeTemplateStore extends CxBaseStoreModule<JobDto> {

    constructor() {
        super("job")
        this.state.entityType = CxEntityType.JOB
    }

    defineActions(actions: any) {
        return super.defineActions({
            async run(context: any, jobId: number) {
                context.state.loading = true
                try {
                    let response = await api.put(`${context.state.entityName}/run/${jobId}`)
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            async restart(context: any, jobId: number) {
                context.state.loading = true
                try {
                    let response = await api.put(`${context.state.entityName}/restart/${jobId}`)
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new CodeTemplateStore().createStore()