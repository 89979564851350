import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }
const _hoisted_2 = { class: "flex mb-2" }
const _hoisted_3 = { class: "ml-2 mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_CxDropdown = _resolveComponent("CxDropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_device_template_form = _resolveComponent("device-template-form")!
  const _component_cx_crud_table = _resolveComponent("cx-crud-table")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.deviceModel.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'deviceModel'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('deviceModel'))),
        grant: {
        perm: _ctx.$perm.DEVICE_MODEL,
        lvl: _ctx.$lvl.WRITE
      }
      }, null, 8, ["isEditing", "onSave", "grant"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event)),
              disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_MODEL, _ctx.$lvl.WRITE)
            }, null, 8, ["placeholder", "modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('deviceFamily', 'lb'),
          v$: _ctx.v$.entity.deviceFamily
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CxDropdown, {
              store: "deviceFamily",
              optionLabel: "name",
              modelValue: _ctx.entity.deviceFamily,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.deviceFamily) = $event)),
              placeholder: _ctx.$t('deviceFamily', 'ph'),
              dropdownOptions: {
            getId: () => _ctx.entity.deviceFamily?.id,
            route: 'deviceFamilyEditor',
            editable: true
          },
              disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_MODEL, _ctx.$lvl.WRITE)
            }, {
              option: _withCtx((props) => [
                _createElementVNode("span", null, _toDisplayString(props.option.name) + " (" + _toDisplayString(props.option.manufacturer.name) + ")", 1)
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "dropdownOptions", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.entity.catalystCenterActivated,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.catalystCenterActivated) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('deviceModel', 'catalystCenterActivated')), 1)
        ])
      ]),
      _withDirectives(_createVNode(_component_cx_crud_table, {
        data: _ctx.deviceTemplateData,
        onLoad: _ctx.loadDeviceTemplates,
        entityName: "deviceTemplate",
        config: _ctx.deviceTemplateConfig,
        loading: false,
        isLarge: true,
        grant: {
        perm: _ctx.$perm.DEVICE_TEMPLATE,
        lvl: _ctx.$lvl.WRITE
      }
      }, {
        "table-actions": _withCtx((props) => [
          _createVNode(_component_Button, {
            icon: "pi pi-window-maximize",
            class: "p-button-secondary",
            onClick: ($event: any) => (_ctx.$cx.goToByIdNewTab('deviceTemplateEditor', props.data.id)),
            disabled: !_ctx.$auth.permits(_ctx.$perm.DEVICE_TEMPLATE, _ctx.$lvl.READ)
          }, null, 8, ["onClick", "disabled"])
        ]),
        editor: _withCtx(({ props }) => [
          _createVNode(_component_device_template_form, {
            editorApi: props,
            deviceModelId: _ctx.id,
            grant: {
          perm: _ctx.$perm.DEVICE_TEMPLATE,
          lvl: _ctx.$lvl.WRITE
        }
          }, null, 8, ["editorApi", "deviceModelId", "grant"])
        ]),
        _: 1
      }, 8, ["data", "onLoad", "config", "grant"]), [
        [_vShow, _ctx.isEditing]
      ])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}