import { createStore, Store } from "vuex";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: Store<any>;
  }
}

// auto load all files as vuex stores that end by *-store.ts
const storeModules: { [key: string]: any } = {};
const autoStore = require.context("../", true, /.*-store\.ts$/)

autoStore.keys().map((file) => [file.replace(/(^.\/.*\/)|(-store\.ts$)/g, ""), autoStore(file)])
  .reduce((s: any, [name, item]) => {
    item.default.namespaced = true;
    storeModules[name] = item.default;
  }, {})

const globalStore = createStore({
  state: {
    ui: {
      settingsVisible: true,
      mainVisible: false,
      loading: false,
      language: 0,
      dropdownOptions: {}
    },
  },
  getters: {
    language(state) {
      return state.ui.language;
    },
  },
  mutations: {},
  actions: {},
  modules: storeModules,
});

export default globalStore;
