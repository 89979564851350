
import { ApprovalState } from "@/types/approval-state";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    label: String,
    v$: Object,
    approvalState: Object as PropType<ApprovalState>,
    prop: String,
    checkbox: {
      type: Boolean,
      default: false
    },
    nomargin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this.$cx.createUid(),
    };
  },
  methods: {
    format(v: any) {
      return v.replace("{len}", this.v$?.$model?.length || 0)
    }
  },
  computed: {
    hasApproval() {
      if (this.approvalState == null) return false
      if (this.approvalState.changes == null) return false
      if (this.prop == null) return false
      if (this.prop in this.approvalState.changes) return true
    },
    approvalChange() {
      if (!this.hasApproval) return ""
      if (this.$cx.isArray(this.approvalState!.changes![this.prop!].oldValue)
        || this.$cx.isArray(this.approvalState!.changes![this.prop!].newValue)
        || this.$cx.isObject(this.approvalState!.changes![this.prop!].oldValue)
        || this.$cx.isObject(this.approvalState!.changes![this.prop!].newValue)) return ""
      return "Old: " + this.approvalState!.changes![this.prop!].oldValue + " \n ------- \n New: " + this.approvalState!.changes![this.prop!].newValue
    },
    errors() {
      if (this.v$ != null)
        if (this.v$.$error)
          return this.v$.$silentErrors || this.v$.$errors || [];
      return [];
    },
    isRequired() {
      if (this.v$ == null) return false;
      return "required" in this.v$;
    }
  },
});
