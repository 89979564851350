
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { DeviceModelDto, DeviceTemplateDto } from "@/types/dto";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        deviceFamily: { required, $autoDirty: true },
      },
    };
  },
  data() {
    return {
      entity: {} as DeviceModelDto,
      initialState: {} as DeviceModelDto,
      deviceTemplateData: [] as DeviceTemplateDto[],
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    deviceModelTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    deviceTemplateConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn(
          "deviceRoles",
          this.$t("deviceRole", 'lb_pl'),
          CxDataType.GetNameFromArray
        ),
        new CxTableColumn("os", this.$t("os", "lb"), CxDataType.GetName),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    getProps(props: any) {
      console.log(props);
    },
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("deviceModel/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

        this.loadDeviceTemplates();
      }
    },
    loadDeviceTemplates() {
      this.$store
        .dispatch("deviceModel/getTemplates", this.id)
        .then((deviceTemplates) => {
          this.deviceTemplateData = deviceTemplates;
        });
    },
    async save(goBack = false) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("deviceModel/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("deviceModel", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("deviceModel")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("deviceModel/create", this.entity)
          .then((newEntity: DeviceModelDto) => {
            this.$cx.notifyCreated(this.$t("deviceModel", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);

            if (goBack)
              this.$cx.goTo("deviceModel")
            else
              this.$cx.goToById("deviceModelEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
  },
});
