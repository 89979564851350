
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { ApiParamDto, CxApiType } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        id: String,
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                url: { required, $autoDirty: true },
                type: { required, $autoDirty: true },
                username: { required, $autoDirty: true },
                name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true }
            },
        };
    },
    data() {
        return {
            entity: {} as ApiParamDto,
            initialState: {} as ApiParamDto,
            apiTypeOptions: this.$cx.mapEnumToOptions(CxApiType)
        };
    },
    mounted() {
        this.load();
    },
    watch: {
        id: function () {
            this.load();
        },
    },
    computed: {
        isEditing() {
            return this.id != null;
        }
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
    },
    methods: {
        load() {
            if (this.isEditing) {
                this.$store
                    .dispatch("apiParam/getById", this.id)
                    .then((entity) => {
                        this.entity = entity;
                        this.initialState = this.$cx.getState(this.entity);
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
            }
        },
        async save(goBack: Boolean) {
            if (await this.$cx.notifyValidationError(this.v$)) return;

            if (this.isEditing) {
                this.$store
                    .dispatch("apiParam/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("apiParam", "lb"));
                        this.initialState = this.$cx.getState(this.entity);
                        if (goBack)
                            this.$cx.goTo("apiParam")
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.$store
                    .dispatch("apiParam/create", this.entity)
                    .then((newEntity: ApiParamDto) => {
                        this.$cx.notifyCreated(this.$t("apiParam", "lb"));
                        this.entity = newEntity;
                        this.initialState = this.$cx.getState(this.entity);
                        if (goBack)
                            this.$cx.goTo("apiParam")
                        else
                            this.$cx.goToById("apiParamEditor", newEntity.id!);
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        },
        async testConnection() {
            this.$store
                .dispatch("apiParam/testConnection", this.id)
                .then((entity) => {
                    this.$cx.success(this.$c('successful'), this.$t('apiParam', 'catalystCenterSuccessful'))
                })
                .catch((error) => {
                    console.log(error)
                    let err = this.$cx.error(error, this.$cx.e("connection"))
                    this.$cx.confirm(this.$cx.e('error'), err, () => { })
                });
        }
    },
});
