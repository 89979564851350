import { DeviceTemplateModelDto } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";
import { api } from "@/_config/api";

class DeviceTemplateModelStore extends CxBaseStoreModule<DeviceTemplateModelDto> {

    constructor() {
        super("deviceTemplateModel")
    }

    defineState(state: any) {
        return super.defineState({

        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            getAllByFilter: async (context: any, filters: any) => {
                context.state.loading = true;
                try {
                    let response = await api.post(context.state.entityName + '/ddfilter', { filters: filters });
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            getDevicePortsByDeviceTemplateId: async (context: any, deviceTemplateId: number) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/${deviceTemplateId}/ports`);
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            saveStackOrder: async (context: any, deviceModels: any[]) => {
                context.state.loading = true;
                try {
                    let response = await api.put(`${context.state.entityName}/stackOrder`, { stackOrderItems: deviceModels });
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },

            deleteWithConfirm: async(context: any, props: any) => {
                context.state.loading = true;
                try {
                    let response = await api.delete(
                        `deviceTemplateModel/${props.id}?confirm=${props.confirm}`
                    );
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            }
        })
    }
}

export default new DeviceTemplateModelStore().createStore()