
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { RuleDto, SelectorDto } from "@/types/dto";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true }
      },
    };
  },
  data() {
    return {
      entity: {} as RuleDto,
      initialState: {} as RuleDto,
      selectorEditorVisible: false,
      selectorEditorCreateMode: false,
      selectorEntity: {} as SelectorDto,
      selectors: [] as SelectorDto[],
      blacklist: [] as SelectorDto[],
      complianceResult: {} as any,
      selectedDevice: {} as any,
      activeSelectorTabIndex: 0
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    ruleTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    getProps(props: any) {
      console.log(props);
    },
    isCompliant(selector: any) {
      if (selector.compliant == null) return true
      return selector.compliant
    },
    onOpenSelectorEditor(selector: SelectorDto, mode: boolean) {
      this.selectorEditorCreateMode = mode
      this.selectorEditorVisible = true
      this.selectorEntity = Object.assign({}, selector)
    },
    onDeviceChanged(ev: any) {
      /*if (this.selectedDevice == null) return
      if (this.selectedDevice.id == null) return
      */
      this.loadComplianceTest(ev.device.id, ev.source.id, ev.target.id)
      console.log(ev)
      // call load compliance test
    },
    emitDeviceChanged(ev: any) {
      (this.$refs as any)["diff"].onDeviceChanged(ev)
    },
    loadComplianceTest(id: number, sourceId: number, targetId: number) {
      this.$store.dispatch("compliance/runTest", {
        ruleId: this.entity.id,
        deviceId: id,
        sourceId: sourceId,
        targetId: targetId
      }).then((data: any) => {
        let selectors = {} as any
        data.matches.forEach((m: any) => {
          if (!(m.selector.name in selectors)) {
            selectors[m.selector.name] = {
              selector: m.selector,
              items: []
            }
          }
          selectors[m.selector.name].items.push(m)
          if (!m.compliant) {
            selectors[m.selector.name].selector.compliant = false
          }
        })
        this.complianceResult = selectors
      })
    },
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("rule/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.selectors = entity.selectors;
            this.blacklist = entity.blacklist;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      }
    },
    loadSelectors() {
      this.$store.dispatch("rule/getSelectors", this.id)
        .then((data) => {
          this.entity.selectors = data.filter((s: SelectorDto) => !s.blacklist)
          this.entity.blacklist = data.filter((s: SelectorDto) => s.blacklist)
          this.selectors = this.entity.selectors as SelectorDto[]
          this.blacklist = this.entity.blacklist as SelectorDto[]
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onDeleteSelector(selector: SelectorDto) {
      this.$cx.confirmDelete(() => {
        this.$store.dispatch("rule/deleteSelector", {
          ruleId: this.entity.id,
          id: selector.id
        })
          .then((r) => {
            this.loadSelectors()
            this.emitDeviceChanged(null)
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
      })
    },
    async onSaveSelector() {
      if (this.selectorEditorCreateMode) {
        this.selectorEntity.blacklist = (this.activeSelectorTabIndex == 1)
        this.$store.dispatch("rule/createSelector", {
          ruleId: this.entity.id,
          entity: this.selectorEntity
        })
          .then((r) => {
            this.selectorEditorVisible = false
            this.loadSelectors()
            this.emitDeviceChanged(null)
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store.dispatch("rule/updateSelector", {
          ruleId: this.entity.id,
          entity: this.selectorEntity
        })
          .then((r) => {
            this.selectorEditorVisible = false
            this.loadSelectors()
            this.emitDeviceChanged(null)
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      }

      this.selectorEditorVisible = false
    },
    async save(goBack = false) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("rule/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("rule", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("rule")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("rule/create", this.entity)
          .then((newEntity: RuleDto) => {
            this.$cx.notifyCreated(this.$t("rule", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);

            if (goBack)
              this.$cx.goTo("rule")
            else
              this.$cx.goToById("ruleEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
  },
});
