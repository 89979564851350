
import {
    CxDataType,
    CxTableColumn,
    CxTableConfig,
} from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
    props: {},
    data() {
        return {
            jobs: []
        };
    },
    computed: {
        tableConfig() {
            return new CxTableConfig([
                new CxTableColumn("name", this.$c("name")),
                new CxTableColumn("schedule", this.$t("schedule", 'lb'), CxDataType.GetName),
                new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
            ]);
        },
    },
    methods: {
        load(filters: any) {
            this.$store
                .dispatch("job/getWithFilter", filters)
                .then((jobs) => {
                    this.jobs = jobs;
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onDelete(data: any) {
            this.$cx.confirmDeleteDetail(
                data.name,
                () => {
                    this.$store
                        .dispatch("job/delete", data.id)
                        .then(() => {
                            this.$cx.notifyDeleted(this.$t("job", "lb"));
                            (<any>this.$refs)['tbl'].reload();
                        })
                        .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
                })
        },
        onClearFilters() {
            (<any>this.$refs)['tbl'].clearFilters()
        },
        onDeleteOldJobs() {
            this.$cx.confirmDelete(() => {
                this.$store.dispatch("schedule/deleteOldJobs")
                    .then(() => {
                        this.$cx.notifyDeleted(this.$t("job", "oldJobs"));
                        (<any>this.$refs)['tbl'].reload();
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
            })
        }
    },
});
