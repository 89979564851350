import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_multi_chip_select = _resolveComponent("cx-multi-chip-select")!
  const _component_CxDropdown = _resolveComponent("CxDropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_cx_label, {
      label: _ctx.$c('name'),
      v$: _ctx.v$.entity.name
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InputText, {
          placeholder: _ctx.$c('name_ph'),
          modelValue: _ctx.entity.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity.name) = $event)),
          disabled: _ctx.disabled
        }, null, 8, ["placeholder", "modelValue", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('deviceRole', 'lb_pl'),
      v$: _ctx.v$.entity.deviceRoles
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cx_multi_chip_select, {
          modelValue: _ctx.entity.deviceRoles,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.deviceRoles) = $event)),
          store: "deviceRole",
          placeholder: _ctx.$t('deviceRole', 'ph_pl'),
          dropdownOptions: {
          route: 'deviceRoleEditor',
          getId: (en) => en?.id
        },
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "placeholder", "dropdownOptions", "disabled"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    _createVNode(_component_cx_label, {
      label: _ctx.$t('os', 'lb'),
      v$: _ctx.v$.entity.os
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CxDropdown, {
          store: "os",
          optionLabel: "name",
          modelValue: _ctx.entity.os,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.os) = $event)),
          placeholder: _ctx.$t('os', 'ph'),
          dropdownOptions: {
        getId: (en) => en?.id,
        route: 'osEditor',
        editable: true
      }
        }, null, 8, ["modelValue", "placeholder", "dropdownOptions"])
      ]),
      _: 1
    }, 8, ["label", "v$"]),
    (_ctx.isEditing)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "float-right p-button-secondary",
          label: _ctx.$t('deviceTemplate', 'newTab'),
          icon: "pi pi-window-maximize",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$cx.goToByIdNewTab('deviceTemplateEditor', _ctx.id)))
        }, null, 8, ["label"]))
      : _createCommentVNode("", true)
  ]))
}