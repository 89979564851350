
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { LoginProfileDto, CxLoginType, CxEntityType, CxApprovalState, ApprovalDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { ApprovalState } from "@/types/approval-state";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: {
          required,
          maxLength: maxLength(vMaxLen.name),
          $autoDirty: true,
        },
        username: {
          required,
          maxLength: maxLength(vMaxLen.name),
          $autoDirty: true,
        },
        password: {
          required,
          maxLength: maxLength(vMaxLen.text),
          $autoDirty: true,
        },
      },
    };
  },
  data() {
    return {
      entity: {} as LoginProfileDto,
      initialEntity: {} as LoginProfileDto,
      approvalState: new ApprovalState(CxEntityType.LOGIN_PROFILE),
      suppressUnsavedChanges: false
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },

    loginTypes() {
      return this.$cx.mapEnumToOptions(CxLoginType)
    },
    isApproval() {
      return this.$cx.isApprovalRoute(this.$router)
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.suppressUnsavedChanges) {
      next()
      return
    }
    this.$cx.notifyUnsavedChanges(next, this.initialEntity, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.approvalState.setStore("loginProfile", this.id!)

        if (this.isApproval) {
          this.approvalState.setStore("loginProfile", this.id!)
          this.approvalState.fetchNewDataset().then((data: ApprovalDto) => {
            this.entity = data.data
            this.initialEntity = this.$cx.getState(this.entity)
          })
        } else {
          this.$store
            .dispatch("loginProfile/getById", this.id)
            .then((entity) => {
              this.entity = entity;
              this.initialEntity = this.$cx.getState(this.entity);
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

          this.approvalState.setStore("loginProfile", this.id!)
          this.approvalState.fetchApprovalData()
        }
      }
    },

    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("loginProfile/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("loginProfile", "lb"));
            this.initialEntity = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("loginProfile")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("loginProfile/create", this.entity)
          .then((newEntity: LoginProfileDto) => {
            this.$cx.notifyCreated(this.$t("loginProfile", "lb"));
            this.entity = newEntity;
            this.initialEntity = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("loginProfile")
            else
              this.$cx.goToById("loginProfileEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },
    onApprovalViewChanged(data: any, isViewingChanges: boolean) {
      if (isViewingChanges) {
        (this.$refs as any)['approval'].apply(this.entity, data)
      } else {
        // (this.$refs as any)['approval'].apply(this.entity, (this.$refs as any)['locDevForm'].initialEntity)
        (this.$refs as any)['approval'].apply(this.entity, this.initialEntity)
      }
    },

    onApprovalChanged(state: CxApprovalState, id: string) {
      if (state == CxApprovalState.APPROVED) {
        this.$cx.goToById("loginProfileEditor", parseInt(id))
        return
      }

      if (state == CxApprovalState.REJECTED && this.isApproval) {
        this.$cx.goTo("loginProfile")
        return
      }

      if (!this.isEditing) {
        this.suppressUnsavedChanges = true
        this.$cx.goToApproval("loginProfileEditor", id)
        return
      }

      (this.$refs as any)['approval'].reset()
      this.load()
    }
  },
});
