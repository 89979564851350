
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      satellites: [],
      canUpload: false,
      latestUpdateName: "",
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store
        .dispatch("update/getLatest")
        .then((latestUpdateName) => {
          this.latestUpdateName = latestUpdateName;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onSave() {
      this.$store
        .dispatch("update/upload", (<any>this.$refs["file"]).files)
        .then(() => {
          this.$cx.success(this.$t('satellite', "updateUploaded"), this.$t('satellite', "updateReady"));
          this.load();
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("uploading")));
    },
    onFileChanged() {
      this.canUpload = (<any>this.$refs["file"]).files.length > 0;
    },
  },
});
