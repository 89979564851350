
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      satellites: [],
      canUpload: false,
      kickstartPaths: [] as any[],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$store
        .dispatch("kickstart/getAll")
        .then((kickstartPaths) => {
          this.kickstartPaths = kickstartPaths.join("\n");
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onUpload() {
      (this.$refs as any)["cx-zip-input"].click();
    },
    onDownload() {
      this.$store
        .dispatch("kickstart/downloadAsZip")
        .then(() => {
          this.$cx.success(this.$t('satellite', "onDownload"), this.$t('satellite', "onZipDownload"));
        })
        .catch((error) =>
          this.$cx.error(error, this.$cx.e("downloading"), this.$cx.e("downloading1"))
        );
    },
    onFileChanged(ev: any) {
      this.$cx.confirm(
        "Zip-Hochladen bestätigen",
        `Soll das Verzeichnis der Kickstarts mit den Daten aus:
        
        ${ev.target.files[0].name}
        
        wirklich ersetzt werden?`,
        async () => {
          try {
            await this.$store.dispatch("kickstart/upload", {
              file: ev.target.files[0],
            });
            this.$cx.success("Zip-Archiv hochgeladen", "Daten wurden ersetzt");
            await this.load();
          } catch (ex) {
            this.$cx.error(ex, "Error processing Zip-File");
          }
        }
      );
    },
  },
});
