import {
  LocationCategoryDto,
  LocationCategoryRoleDto,
  LocationCategoryRolePortRelDto,
} from "@/types/dto";
import { api } from "@/_config/api";
import CxApi, { ApiIdParams } from "@/types/cx-api";

const entityName = "location/category";
const cxApi = new CxApi<LocationCategoryDto>(entityName);

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    async getWithFilter(context: any, props: any) {
      try {
        context.state.loading = true;
        let response = await api.get(`${entityName}/filter`, {
          params: {
            ...{
              page: props.page,
              size: props.size,
            },
            ...props.filters,
          },
        });
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getAll(context: any) {
      context.state.loading = true;
      try {
        let response = await cxApi.getAll();
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getById(context: any, id: number) {
      context.state.loading = true;
      try {
        let response = await cxApi.getById(id);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getState(context: any, locationCategoryId: any) {
      context.state.loading = true;
      try {
        let response = await api.get(
          `${entityName}/${locationCategoryId}/role/state`
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async save(context: any, entity: LocationCategoryDto) {
      context.state.loading = true;
      try {
        let response = await cxApi.save(entity);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async create(context: any, entity: LocationCategoryDto) {
      context.state.loading = true;
      try {
        let response = await cxApi.create(entity);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async deleteWithConfirm(context: any, props: any) {
      context.state.loading = true;
      try {
        let response = await api.delete(
            `location/category/${props.id}?confirm=${props.confirm}`
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getRoles(context: any, categoryId: number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/${categoryId}/role`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async getRoleById(context: any, roleId: number) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/role/${roleId}`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async saveRole(context: any, data: ApiIdParams<LocationCategoryRoleDto>) {
      context.state.loading = true;
      try {
        let response = await api.put(
          `${entityName}/${data.id}/role/${data.entity.id}`,
          data.entity
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async createRole(context: any, data: ApiIdParams<LocationCategoryRoleDto>) {
      context.state.loading = true;
      try {
        let response = await api.post(
          `${entityName}/${data.id}/role`,
          data.entity
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async deleteRole(context: any, props: any) {
      context.state.loading = true;
      console.log(props);
      try {
        let response = await api.delete(
          `${entityName}/role/${props.id}?confirm=${props.confirm}`
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    // Connections
    async getConnections(context: any, id: any) {
      context.state.loading = true;
      try {
        let response = await api.get(`${entityName}/${id}/connection`);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
    async createConnection(
      context: any,
      entity: LocationCategoryRolePortRelDto
    ) {
      context.state.loading = true;
      try {
        let response = await api.post(
          `${entityName}/${entity.locationCategory!.id}/connection`,
          entity
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async deleteConnection(context: any, params: any) {
      context.state.loading = true;
      try {
        let response = await api.delete(
          `${entityName}/${params.categoryId}/connection/${params.id}`
        );
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getImportTemplate(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(entityName + `/importTemplate`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async getExport(context: any) {
      context.state.loading = true;
      try {
        let response = await api.get(entityName + `/export?type=CSV`);
        let attachment =
          response.headers["content-disposition"] ||
          response.headers["Content-Disposition"];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let filename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.exec(
          attachment!
        )![0];
        filename = filename.split("=")[1].replaceAll('"', "");
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        context.state.loading = false;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },

    async importData(context: any, payload: any) {
      context.state.loading = true;
      try {
        let url = `/import/verify?delimiter=${payload.delimiter}`;
        if (payload.save) {
          url = `/import/save?delimiter=${payload.delimiter}`;
        }
        let response = await api.post(entityName + url, payload.file);
        context.state.loading = false;
        return response.data;
      } catch (error) {
        console.error(error);
        context.state.loading = false;
        return Promise.reject(error);
      }
    },
  },
};
