
import { CxDefaultPermissions, CxPermission } from "@/types/cx-permission";
import InputText from "primevue/inputtext";
import SelectButton from "primevue/selectbutton";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        height: {
            type: String,
            default: "100%"
        },
        width: {
            type: String,
            default: "100%"
        },
        debug: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: String,
            default: CxDefaultPermissions
        },
    },
    data() {
        return {
            sel: null,
            permissionSearchText: null as any,
            permissionsCheckbox: [] as any[]
        };
    },
    mounted() {
        this.permissionsCheckbox = Object.entries(this.$cx.mapEnumToKvp(CxPermission))
            .filter(item => item[0] != 'NONE')
            .map(item => ({
                label: item[0],
                index: item[1]
            }))

        if (this.debug)
            this.permissionSearchText = this.$cx.getStorage("cx.permissionSearch", null as any)
    },
    computed: {
        permissionOptions() {
            if (!this.$cx.isNullOrEmpty(this.permissionSearchText))
                return this.permissionsCheckbox.filter(p => p.label.toLowerCase()
                    .includes(this.permissionSearchText!.toLowerCase()))
            return this.permissionsCheckbox
        },
        style() {
            return `max-width:${this.width};max-height:${this.height}`
        },
        containerStyle() {
            return `height: calc(${this.height} - 50px)`
        }
    },
    watch: {
        permissionSearchText: function (s: string) {
            if (this.debug)
                localStorage.setItem("cx.permissionSearch", s)
        }
    },
    methods: {
        getPermission(permission: any) {
            return this.modelValue.charAt(permission.index)
        },
        setPermission(ev: any, permission: any) {
            if (this.$cx.isNullOrEmpty(ev.value)) return
            this.$emit('update:modelValue', this.modelValue.substring(0, permission.index) + ev.value + this.modelValue.substring(permission.index + ev.value.length))
        }
    },
});
