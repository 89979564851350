
import {
    CxDataType,
    CxTableColumn,
    CxTableConfig,
} from "@/types/cx-table-config";
import { defineComponent } from "vue";

export default defineComponent({
    props: {},
    data() {
        return {
            apiParams: []
        };
    },
    computed: {
        tableConfig() {
            return new CxTableConfig([
                new CxTableColumn("name", this.$c("name")),
                new CxTableColumn("type", this.$t("apiParam", "apiType")),
                new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
            ]);
        }
    },
    methods: {
        load(filters: any) {
            this.$store
                .dispatch("apiParam/getWithFilter", filters)
                .then((apiParams) => {
                    this.apiParams = apiParams;
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        onDelete(data: any) {
            this.$cx.confirmDeleteDetail(
                data.name,
                () => {
                    this.$store
                        .dispatch("apiParam/delete", data.id)
                        .then(() => {
                            this.$cx.notifyDeleted(this.$t("apiParam", "lb"));
                            (<any>this.$refs)['tbl'].reload()
                        })
                        .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
                })
        },
        onClearFilters() {
            (<any>this.$refs)['tbl'].clearFilters()
        }
    },
});
