
import { AppContextDto, CxEntityType } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {

            },
        };
    },
    data() {
        return {
            entity: {
                props: {}
            } as AppContextDto,
            initialState: {} as AppContextDto,
            approvalKeys: [
                CxEntityType.SCHEDULE,
                CxEntityType.CODE_TEMPLATE,
                CxEntityType.CONFIGURATION,
                CxEntityType.LOGIN_PROFILE,
                CxEntityType.LOCATION_DEVICE,
                CxEntityType.JOB
            ]
        };
    },
    mounted() {
        this.load();
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
    },
    methods: {
        load() {
            this.$store
                .dispatch("appContext/getSettings")
                .then((entity) => {
                    this.entity = entity;
                    this.initialState = this.$cx.getState(this.entity);
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        async save(goBack: Boolean) {
            if (await this.$cx.notifyValidationError(this.v$)) return;

            this.$store
                .dispatch("appContext/saveSettings", this.entity)
                .then(() => {
                    this.$cx.notifySaved(this.$t("management", "settings"));
                    this.initialState = this.$cx.getState(this.entity);
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("saving")));

        },
        getApprovalValue(key: string) {
            if (!(key in (this.entity.props?.approvalEntities as any))) {
                (this.entity.props!.approvalEntities as any)[key] = false;
            }
            return (this.entity.props?.approvalEntities as any)[key];
        },
        onChangeApprovalValue(event: any, key: string) {
            if (key in (this.entity.props?.approvalEntities as any))
                (this.entity.props?.approvalEntities as any)[key] = event
        }
    },
});
