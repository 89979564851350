
import { LocationDeviceIpDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { CxFormApi } from "@/types/cx-form-api";
import { defineComponent, PropType } from "vue";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";

export default defineComponent({
    props: {
        editorApi: {
            type: Object as PropType<CxFormApi>,
        },
        locationDeviceId: {
            type: String
        },
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        },
    },
    data() {
        return {
            id: -1 as number,
            entity: {} as LocationDeviceIpDto,
        };
    },
    setup: () => ({ v$: useVuelidate() as any }),
    validations() {
        return {
            entity: {
                ip: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
            },
        };
    },
    computed: {
        isEditing() {
            return this.id > 0;
        },
        disabled() {
            if (this.grant == null) return false
            if (this.grant.perm == CxPermission.NONE) return false
            return !this.$auth.permits(this.grant.perm, this.grant.lvl)
        }
    },
    created() {
        this.editorApi!.onSave = async (closeFn: any) => {
            if (await this.$cx.notifyValidationError(this.v$)) return false;
            this.entity.locationDevice = { id: parseInt(this.locationDeviceId!) }
            if (this.isEditing) {
                this.$store
                    .dispatch("locationDeviceIp/save", this.entity)
                    .then(() => {
                        this.$cx.notifySaved(this.$t("locationDeviceIp", "lb"));
                        closeFn();
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
            } else {
                this.$store
                    .dispatch("locationDeviceIp/create", this.entity)
                    .then((data) => {
                        this.$cx.notifyCreated(this.$t("locationDeviceIp", "lb"));
                        this.editorApi!.load(data.id);
                        closeFn()
                    })
                    .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
            }
        };
        this.editorApi!.load = (id: number) => {
            this.id = id;
            this.$store.dispatch("locationDeviceIp/getById", id).then((entity) => {
                this.entity = entity;
            });
        };
        this.editorApi!.delete = (id: number) => {
            this.$store.dispatch("locationDeviceIp/delete", id).then(() => {
                this.$cx.notifyDeleted(this.$t("locationDeviceIp", "lb"));
            });
        };
        this.editorApi!.reset = () => {
            this.entity = {} as LocationDeviceIpDto;
        };
        this.editorApi!.isEditing = () => this.id > 0;
    },
    methods: {

    }
});
