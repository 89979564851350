import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_approval = _resolveComponent("cx-approval")!
  const _component_cx_approval_save_button = _resolveComponent("cx-approval-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.loginProfile.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'loginProfile')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_approval, {
        approvalState: _ctx.approvalState,
        ref: "approval",
        entity: _ctx.entity,
        onViewChanged: _ctx.onApprovalViewChanged,
        isNewDataset: _ctx.isApproval
      }, null, 8, ["approvalState", "entity", "onViewChanged", "isNewDataset"]),
      _createVNode(_component_cx_approval_save_button, {
        approvalState: _ctx.approvalState,
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        entity: _ctx.entity,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('loginProfile'))),
        initialEntity: _ctx.initialEntity,
        disableSaveAndBack: true,
        onStateChanged: _ctx.onApprovalChanged,
        v$: _ctx.v$,
        isNewDataset: _ctx.isApproval,
        grant: {
          perm: _ctx.$perm.LOGIN_PROFILE,
          lvl: _ctx.$lvl.WRITE
        }
      }, null, 8, ["approvalState", "isEditing", "onSave", "entity", "initialEntity", "onStateChanged", "v$", "isNewDataset", "grant"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name,
          approvalState: _ctx.approvalState,
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event)),
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["placeholder", "modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "v$", "approvalState"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('username'),
          v$: _ctx.v$.entity.username,
          approvalState: _ctx.approvalState,
          prop: "username"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('username_ph'),
              modelValue: _ctx.entity.username,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.username) = $event)),
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["placeholder", "modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "v$", "approvalState"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('password'),
          v$: _ctx.v$.entity.password,
          approvalState: _ctx.approvalState,
          prop: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Password, {
              modelValue: _ctx.entity.password,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.password) = $event)),
              disabled: !_ctx.approvalState.formEnabled,
              placeholder: _ctx.$c('password_ph')
            }, null, 8, ["modelValue", "disabled", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "v$", "approvalState"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('default'),
          approvalState: _ctx.approvalState,
          prop: "default",
          checkbox: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Checkbox, {
              binary: true,
              modelValue: _ctx.entity.default,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.default) = $event)),
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('loginProfile', 'loginType'),
          approvalState: _ctx.approvalState,
          prop: "loginType"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Dropdown, {
              placeholder: _ctx.$t('loginProfile', 'loginType_ph'),
              modelValue: _ctx.entity.loginType,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.loginType) = $event)),
              options: _ctx.loginTypes,
              optionLabel: "label",
              optionValue: "value",
              disabled: !_ctx.approvalState.formEnabled
            }, null, 8, ["placeholder", "modelValue", "options", "disabled"])
          ]),
          _: 1
        }, 8, ["label", "approvalState"])
      ])
    ]),
    _: 1
  }, 8, ["loading", "title"]))
}