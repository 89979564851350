import { RouteRecordRaw } from "vue-router"

import IPPool from "./ipPool/IPPool.vue";
import IPPoolEditor from "./ipPool/IPPoolEditor.vue";
import VirtualNetwork from "./VirtualNetwork.vue";
import VirtualNetworkEditor from "./VirtualNetworkEditor.vue";
import LogicalInterface from "./logicalInterface/LogicalInterface.vue";
import LogicalInterfaceEditor from "./logicalInterface/LogicalInterfaceEditor.vue";

const routes: Array<RouteRecordRaw> = [
    // IP-Pool
    {
        path: "/ipPool",
        name: "ipPool",
        component: IPPool,
        meta: { menu: "ipPool" },
    },
    {
        path: "/ipPool/new",
        name: "ipPoolEditorNew",
        component: IPPoolEditor,
        meta: { menu: "ipPool" },
    },
    {
        path: "/ipPool/:id",
        name: "ipPoolEditor",
        component: IPPoolEditor,
        meta: { menu: "ipPool" },
        props: true,
    },
    {
        path: "/virtualNetwork",
        name: "virtualNetwork",
        component: VirtualNetwork,
        meta: { menu: "management" },
    },
    {
        path: "/virtualNetwork/new",
        name: "virtualNetworkEditorNew",
        component: VirtualNetworkEditor,
        meta: { menu: "management" },
    },
    {
        path: "/virtualNetwork/:id",
        name: "virtualNetworkEditor",
        component: VirtualNetworkEditor,
        meta: { menu: "management" },
        props: true,
    },
    // Logical-Interface
    {
        path: "/logicalInterface",
        name: "logicalInterface",
        component: LogicalInterface,
        meta: { menu: "management" },
    },
    {
        path: "/logicalInterface/new",
        name: "logicalInterfaceEditorNew",
        component: LogicalInterfaceEditor,
        meta: { menu: "management" },
    },
    {
        path: "/logicalInterface/:id",
        name: "logicalInterfaceEditor",
        component: LogicalInterfaceEditor,
        meta: { menu: "management" },
        props: true,
    }
]

export default routes