import { RouteRecordRaw } from "vue-router"

import CiscoAciOverview from "./CiscoAciOverview.vue"
import CiscoAciSettings from "./CiscoAciSettings.vue"
import CiscoAciEpInformation from "./CiscoAciEpInformation.vue"
import CiscoAciSpineCoopDb from "./CiscoAciSpineCoopDb.vue"
import CiscoAciGetTenants from "./CiscoAciGetTenants.vue"
import CiscoAciGetEpgs from "./CiscoAciGetEpgs.vue"
import CiscoAciGetInterfaceEpgs from "./CiscoAciGetInterfaceEpgs.vue"
import CiscoAciInterfacesForEpg from "./CiscoAciInterfacesForEpg.vue"
import CiscoAciGetApplicationProfiles from "./CiscoAciGetApplicationProfiles.vue"
import CiscoAciInterfacesWithErrors from "./CiscoAciInterfacesWithErrors.vue"
import CiscoAciLogicalStructure from "./CiscoAciLogicalStructure.vue"

const aciRoutes: Array<RouteRecordRaw> = [

    {
        path: '/apics',
        name: 'apics',
        component: CiscoAciOverview,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/logicalStructure',
        name: 'apicLogicalStructure',
        component: CiscoAciLogicalStructure,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/epInformation',
        name: 'apicGetEpInformation',
        component: CiscoAciEpInformation,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/spineCoopDb',
        name: 'apicSpineCoopDb',
        component: CiscoAciSpineCoopDb,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/interfacesForEpg',
        name: 'apicInterfacesForEpg',
        component: CiscoAciInterfacesForEpg,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/interfaceEpgs',
        name: 'apicInterfaceEpgs',
        component: CiscoAciGetInterfaceEpgs,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/tenants',
        name: 'apicGetTenants',
        component: CiscoAciGetTenants,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/epgs',
        name: 'apicEpgs',
        component: CiscoAciGetEpgs,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/applicationProfiles',
        name: 'apicGetApplicationProfiles',
        component: CiscoAciGetApplicationProfiles,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/interfaceErrors',
        name: 'apicGetInterfaceErrors',
        component: CiscoAciInterfacesWithErrors,
        meta: { menu: "apics" }
    },
    {
        path: '/apics/settings',
        name: 'apicSettings',
        component: CiscoAciSettings,
        meta: { menu: "apics" }
    }
]

export default aciRoutes