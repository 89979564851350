import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_approval = _resolveComponent("cx-approval")!
  const _component_cx_approval_save_button = _resolveComponent("cx-approval-save-button")!
  const _component_code_template_form = _resolveComponent("code-template-form")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_code_input = _resolveComponent("cx-code-input")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.codeTemplate.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'codeTemplate')
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('codeTemplate', 'openTestDialogBtn'),
        icon: "pi pi-bolt",
        onClick: _cache[0] || (_cache[0] = () => { _ctx.isTemplateDialogVisible = true })
      }, null, 8, ["label"]),
      _createVNode(_component_cx_approval, {
        approvalState: _ctx.approvalState,
        ref: "approval",
        entity: _ctx.entity,
        onViewChanged: _ctx.onApprovalViewChanged
      }, null, 8, ["approvalState", "entity", "onViewChanged"]),
      _createVNode(_component_cx_approval_save_button, {
        approvalState: _ctx.approvalState,
        isEditing: _ctx.isEditing,
        canCreate: true,
        onSave: _ctx.save,
        entity: _ctx.entity,
        initialEntity: _ctx.initialEntity,
        onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$cx.goTo('codeTemplate'))),
        disableSaveAndBack: true,
        onStateChanged: _ctx.onApprovalChanged,
        grant: {
          perm: _ctx.$perm.CODE_TEMPLATE,
          lvl: _ctx.$lvl.WRITE
        }
      }, null, 8, ["approvalState", "isEditing", "onSave", "entity", "initialEntity", "onStateChanged", "grant"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_code_template_form, {
        ref: "codeTemplateForm",
        editorApi: _ctx.codeTemplateApi,
        onEntity: _ctx.onEntityChanged,
        approvalState: _ctx.approvalState
      }, null, 8, ["editorApi", "onEntity", "approvalState"]),
      (_ctx.isTemplateDialogVisible)
        ? (_openBlock(), _createBlock(_component_Dialog, {
            key: 0,
            modal: true,
            closable: true,
            closeOnEscape: true,
            header: _ctx.$t('codeTemplate', 'testerDialogTitle'),
            visible: _ctx.isTemplateDialogVisible,
            "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isTemplateDialogVisible) = $event)),
            breakpoints: { '960px': '75vw', '640px': '100vw' },
            style: { width: '72vw' }
          }, {
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                autofocus: "",
                label: _ctx.$t('codeTemplate', 'calculateTemplate'),
                icon: "pi pi-bolt",
                onClick: _ctx.onCalculateTemplate
              }, null, 8, ["label", "onClick"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_cx_label, {
                label: _ctx.$t('locationDevice', 'lb'),
                approvalState: _ctx.approvalState
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_dropdown, {
                    modelValue: _ctx.dialogLocationDevice,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogLocationDevice) = $event)),
                    store: "locationDevice",
                    optionLabel: "name",
                    placeholder: _ctx.$t('locationDevice', 'ph')
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('codeTemplate', 'calculatedTemplate')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_code_input, {
                    height: "calc(100vh - 350px)",
                    modelValue: _ctx.dialogCodeTemplateText,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogCodeTemplateText) = $event)),
                    lang: "python",
                    ref: "code"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["header", "visible"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading", "title"]))
}