import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "scd-charts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (chart, c) => {
        return (_openBlock(), _createBlock(_component_Chart, {
          key: c,
          type: "bar",
          data: chart,
          options: {
                responsive: false,
                barThickness: 30,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057',
                            font: {
                                size: 10
                            }
                        },
                        position: 'bottom'
                    },
                    datalabels: {
                        color: 'white',
                        labels: {
                            title: {
                                color: 'white'
                            }
                        }
                    },
                }
            }
                ,
          width: 300,
          height: 200,
          plugins: [_ctx.chartPlugin]
        }, null, 8, ["data", "plugins"]))
      }), 128))
    ]),
    _createVNode(_component_DataTable, {
      class: "smgmt-table",
      value: _ctx.tableData,
      filters: _ctx.tableFilters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableFilters) = $event)),
      filterDisplay: "row",
      showGridlines: "",
      paginator: "",
      rows: 20,
      rowsPerPageOptions: [5, 10, 20, 50],
      scrollable: "",
      "scroll-height": "calc(100vh - 215px)"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "manufacturerName",
          header: _ctx.$t('manufacturer', 'lb'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "deviceModelName",
          header: _ctx.$t('deviceModel', 'lb'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "count",
          header: _ctx.$t('softwareCompliance', 'totalCount'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "valid",
          header: _ctx.$t('softwareCompliance', 'isValid'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "invalidTable",
          header: _ctx.$t('softwareCompliance', 'inValid'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "percentage",
          header: _ctx.$t('softwareCompliance', 'compliancePercentage'),
          sortable: ""
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onInput: filterCallback,
              class: "p-column-filter",
              placeholder: _ctx.$c('searchText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput", "placeholder"])
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["value", "filters"])
  ]))
}