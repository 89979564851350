import { api } from "@/_config/api";
import { ApprovalDto, CxEntityType } from "./dto";

class CxBaseStoreModule<T> {
    state = {
        loading: false,
        entityName: "",
        entityType: CxEntityType.GLOBAL
    }

    constructor(entityName: string) {
        this.state.entityName = entityName
    }

    createStore() {
        return {
            namespaced: true,
            state: this.defineState({}),
            actions: this.defineActions({})
        }
    }

    defineState(state: any) {
        return {
            ...this.state,
            ...state
        }
    }

    defineActions(actions: any) {
        return {
            ...{
                deleteWithConfirm: this.deleteWithConfirm,
                getWithFilter: this.getWithFilter,
                getApproval: this.getApproval,
                approve: this.approve,
                getById: this.getById,
                getAll: this.getAll,
                create: this.create,
                delete: this.delete,
                save: this.save
            }, ...actions
        }
    }

    async getApproval(context: any, props: any) {
        try {

            let response = await api.get(`approval/${context.state.entityType}/${props.id}`)
            return response.data
        } catch (error) {
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async getWithFilter(context: any, props: any) {
        try {
            context.state.loading = true;
            let response = await api.get(`${context.state.entityName}/filter`, {
                params: {
                    ...{
                        page: props.page,
                        size: props.size
                    }, ...props.filters
                }
            });
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async getAll(context: any) {
        context.state.loading = true;
        try {
            let response = await api.get(context.state.entityName);
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async getById(context: any, id: number) {
        context.state.loading = true;
        try {
            let response = await api.get(`${context.state.entityName}/${id}`);
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async save(context: any, entity: T) {
        context.state.loading = true;
        try {
            let response = await api.put(`${context.state.entityName}/${(<any>entity).id}`, entity);
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async create(context: any, entity: any) {
        context.state.loading = true;
        try {
            let response = await api.post(context.state.entityName, entity);
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async delete(context: any, id: number) {
        context.state.loading = true;
        try {
            let response = await api.delete(`${context.state.entityName}/${id}`);
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async deleteWithConfirm(context: any, props: any) {
        context.state.loading = true;
        try {
            let response = await api.delete(
                `${context.state.entityName}/${props.id}?confirm=${props.confirm}`
            );
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }

    async approve(context: any, approval: ApprovalDto) {
        context.state.loading = true;
        try {
            let response = await api.put(`${context.state.entityName}/approve`, approval);
            context.state.loading = false;
            return response.data;
        } catch (error) {
            console.error(error);
            context.state.loading = false;
            return Promise.reject(error);
        }
    }
}

export default CxBaseStoreModule