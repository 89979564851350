import { RouteRecordRaw } from "vue-router"

import Customers from "./Customers.vue";
import CustomerEditor from "./CustomerEditor.vue";
import SatelliteUpdate from "./SatelliteUpdate.vue";
import SatelliteKickstarts from "./SatelliteKickstarts.vue";
import SatelliteManagement from "./SatelliteManagement.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/customers",
        name: "customers",
        component: Customers,
        meta: { menu: "satellite" },
    },
    {
        path: "/customer/:id",
        name: "customerEditor",
        component: CustomerEditor,
        meta: { menu: "satellite" },
        props: true,
    },
    {
        path: "/customer/new",
        name: "customerEditorNew",
        component: CustomerEditor,
        meta: { menu: "satellite" },
    },
    {
        path: "/satellite-update",
        name: "satelliteUpdate",
        component: SatelliteUpdate,
        meta: { menu: "satellite" },
    },
    {
        path: "/satellite-kickstarts",
        name: "satelliteKickstarts",
        component: SatelliteKickstarts,
        meta: { menu: "satellite" },
    },
    {
        path: "/satellite",
        name: "SatelliteManagement",
        component: SatelliteManagement,
        meta: { menu: "satellite" }
    }
]


export default routes