import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_multi_chip_select = _resolveComponent("cx-multi-chip-select")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.locationType.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'locationType'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('locationType')))
      }, null, 8, ["isEditing", "onSave"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('vlan', 'lb_pl')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_multi_chip_select, {
              modelValue: _ctx.entity.vlans,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.vlans) = $event)),
              store: "vlan",
              placeholder: _ctx.$t('vlan', 'ph_pl'),
              dropdownOptions: {
            route: 'virtualNetworkEditor',
            getId: (en) => en?.id,
            editable: false
          }
            }, null, 8, ["modelValue", "placeholder", "dropdownOptions"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$c('description'),
          v$: _ctx.v$.entity.description
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Textarea, {
              placeholder: _ctx.$c('description_ph'),
              modelValue: _ctx.entity.description,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.description) = $event)),
              rows: "5",
              cols: "30"
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"])
      ])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}