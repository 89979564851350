import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = { class: "cx-form-h-30 flex-1" }
const _hoisted_3 = { style: {"margin-top":"-61px"} }
const _hoisted_4 = { style: {"margin-top":"-61px"} }
const _hoisted_5 = { class: "flex justify-content-end cx-config-actions" }
const _hoisted_6 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_approval = _resolveComponent("cx-approval")!
  const _component_cx_approval_save_button = _resolveComponent("cx-approval-save-button")!
  const _component_location_device_form = _resolveComponent("location-device-form")!
  const _component_Column = _resolveComponent("Column")!
  const _component_serial_number_form = _resolveComponent("serial-number-form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_crud_table = _resolveComponent("cx-crud-table")!
  const _component_location_device_ip_form = _resolveComponent("location-device-ip-form")!
  const _component_cx_crud_table_filter = _resolveComponent("cx-crud-table-filter")!
  const _component_cx_config_comparer = _resolveComponent("cx-config-comparer")!
  const _component_cx_table = _resolveComponent("cx-table")!
  const _component_cx_ztp_log_viewer = _resolveComponent("cx-ztp-log-viewer")!
  const _component_cx_log = _resolveComponent("cx-log")!
  const _component_cx_tabs = _resolveComponent("cx-tabs")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.locationDevice.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'locationDevice'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_approval, {
        approvalState: _ctx.approvalState,
        ref: "approval",
        entity: _ctx.entity,
        onViewChanged: _ctx.onApprovalViewChanged
      }, null, 8, ["approvalState", "entity", "onViewChanged"]),
      _createVNode(_component_cx_approval_save_button, {
        approvalState: _ctx.approvalState,
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        canCreate: true,
        entity: _ctx.entity,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('location'))),
        disableSaveAndBack: true,
        initialEntity: _ctx.initialEntity,
        onStateChanged: _ctx.onApprovalChanged,
        grant: {
          perm: _ctx.$perm.LOCATION_DEVICE,
          lvl: _ctx.$lvl.WRITE
        }
      }, null, 8, ["approvalState", "isEditing", "onSave", "entity", "initialEntity", "onStateChanged", "grant"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_cx_tabs, {
        isEditing: _ctx.isEditing,
        stateKey: "location_device_editor",
        onOnTabChanged: _ctx.onTabChanged,
        headers: [
      { key: 'basic_data', title: _ctx.$c('basicData') },
      { key: 'access', title: _ctx.$t('locationDevice', 'serial') },
      { key: 'ip', title: _ctx.$t('locationDevice', 'ip') },
      { key: 'configuration', title: _ctx.$t('locationDevice', 'configuration') },
      { key: 'pnp_requests', title: _ctx.$t('locationDevice', 'pnpRequest') },
      { key: 'ztp_logs', title: _ctx.$t('locationDevice', 'ztpLogs') },
      { key: 'logs', title: 'Logs' }
    ]
      }, {
        basic_data: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_location_device_form, {
                ref: "locDevForm",
                editorApi: _ctx.deviceApi,
                onEntity: _ctx.onEntityChanged,
                approvalState: _ctx.approvalState
              }, null, 8, ["editorApi", "onEntity", "approvalState"])
            ])
          ])
        ]),
        access: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_cx_crud_table, {
                  key: 0,
                  onLoad: _ctx.loadSerialNumbers,
                  data: _ctx.serialNumbersOrdered,
                  entityName: "serialNumber",
                  config: _ctx.serialNumberTableConfig,
                  title: " ",
                  loading: false,
                  onRowReorder: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onReorder($event))),
                  canDeleteFn: (id) => id > 0,
                  sortable: false,
                  disableCreate: true
                }, {
                  deviceModel: _withCtx(() => [
                    _createVNode(_component_Column, {
                      header: _ctx.$t('deviceModel', 'lb')
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(data.deviceTemplateModel?.deviceModel?.name), 1)
                      ]),
                      _: 1
                    }, 8, ["header"])
                  ]),
                  editor: _withCtx(({ props, entity }) => [
                    _createVNode(_component_serial_number_form, {
                      editorApi: props,
                      locationDeviceId: _ctx.id,
                      serial: entity
                    }, null, 8, ["editorApi", "locationDeviceId", "serial"])
                  ]),
                  actions: _withCtx(() => [
                    (_ctx.entity.deviceTemplate != null)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          icon: "pi pi-window-maximize",
                          class: "p-button-secondary",
                          label: _ctx.$t('deviceTemplate', 'lb'),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$cx.goToByIdNewTab('deviceTemplateEditor', _ctx.entity.deviceTemplate!.id!)))
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["onLoad", "data", "config", "canDeleteFn"]))
              : _createCommentVNode("", true)
          ])
        ]),
        ip: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_cx_crud_table_filter, {
              stateKey: "location_device_ips",
              onLoad: _ctx.loadLocationDeviceIps,
              data: _ctx.locationDeviceIps,
              tableStyle: "height: calc(100vh - 145px)",
              entityName: "locationDeviceIp",
              title: " ",
              config: _ctx.locationDeviceIpTableConfig,
              loading: false,
              grant: {
              perm: _ctx.$perm.LOCATION_DEVICE_IP,
              lvl: _ctx.$lvl.WRITE
            }
            }, {
              editor: _withCtx(({ props }) => [
                _createVNode(_component_location_device_ip_form, {
                  locationDeviceId: _ctx.id,
                  editorApi: props,
                  grant: {
                perm: _ctx.$perm.LOCATION_DEVICE_IP,
                lvl: _ctx.$lvl.WRITE
              }
                }, null, 8, ["locationDeviceId", "editorApi", "grant"])
              ]),
              _: 1
            }, 8, ["onLoad", "data", "config", "grant"])
          ])
        ]),
        configuration: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              class: "mr-2",
              icon: "pi pi-bolt",
              label: _ctx.$t('locationDevice', 'generateConfig'),
              onClick: _ctx.onGenerateConfig
            }, null, 8, ["label", "onClick"])
          ]),
          _createVNode(_component_cx_config_comparer, {
            id: _ctx.id,
            ref: "diff"
          }, null, 8, ["id"])
        ]),
        pnp_requests: _withCtx(() => [
          _createVNode(_component_cx_table, {
            onLoad: _ctx.loadPnpRequests,
            data: _ctx.pnpRequestData,
            entityName: "pnp",
            config: _ctx.pnpRequestTableConfig,
            loading: false
          }, null, 8, ["onLoad", "data", "config"])
        ]),
        ztp_logs: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_cx_ztp_log_viewer, {
              ref: "ztp-viewer",
              queryLocDevId: _ctx.id
            }, null, 8, ["queryLocDevId"])
          ])
        ]),
        logs: _withCtx(() => [
          _createVNode(_component_cx_log, { id: _ctx.id }, null, 8, ["id"])
        ]),
        _: 1
      }, 8, ["isEditing", "onOnTabChanged", "headers"]),
      _createVNode(_component_Dialog, {
        header: _ctx.configResultHeader,
        visible: _ctx.isConfigResultDialogVisible,
        "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isConfigResultDialogVisible) = $event)),
        modal: true,
        breakpoints: { '960px': '75vw', '640px': '100vw' },
        style: { width: '50vw' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cx_label, { label: "Source" }, {
            default: _withCtx(() => [
              _createVNode(_component_Textarea, {
                rows: "20",
                readonly: true,
                autoResize: false,
                value: _ctx.configOutput.result || _ctx.configOutput.error || _ctx.configResult.output,
                placeholder: "Script"
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["header", "visible"])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}