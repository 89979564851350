import { api } from "@/_config/api";
import { ScheduleDto, CxEntityType } from "@/types/dto";
import CxBaseStoreModule from "@/types/cx-store-module";

class ScheduleStore extends CxBaseStoreModule<ScheduleDto> {

    constructor() {
        super("schedule")
        this.state.entityType = CxEntityType.SCHEDULE
    }

    defineState(state: any) {
        return super.defineState({
            loadingOutput: false
        })
    }

    defineActions(actions: any) {
        return super.defineActions({
            activate: async (context: any, props: any) => {
                try {
                    let response = await api.put(`${context.state.entityName}/${props.scheduleId}/activate`, {}, {
                        params: {
                            activated: props.activated
                        }
                    })
                    return response.data
                } catch (error) {
                    console.error(error);
                    context.state.loadingOutput = false;
                    return Promise.reject(error);
                }
            },
            async restartJob(context: any, props: any) {
                context.state.loading = true
                try {
                    let response = await api.put(`${context.state.entityName}/${props.scheduleId}/restart/${props.jobId}`)
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
            async deleteOldJobs(context: any) {
                context.state.loading = true
                try {
                    let response = await api.delete(`${context.state.entityName}/oldJobs`)
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },

            getScheduleStatus: async (context: any, props: any) => {
                try {
                    let response = await api.get(`${context.state.entityName}/${props.scheduleId}/status`, {
                        params: {
                            jobId: props.jobId
                        },
                    })
                    return response.data
                } catch (error) {
                    console.error(error);
                    context.state.loadingOutput = false;
                    return Promise.reject(error);
                }
            },
            getOutputWithFilter: async (context: any, props: any) => {
                try {
                    context.state.loadingOutput = true;
                    let response = await api.get(`${context.state.entityName}/output/filter`, {
                        params: {
                            ...{
                                page: props.page,
                                size: props.size,
                                scheduleId: props.scheduleId
                            }, ...props.filters
                        }
                    });
                    context.state.loadingOutput = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loadingOutput = false;
                    return Promise.reject(error);
                }
            },
            getAllByLocationId: async (context: any, id: number) => {
                context.state.loading = true;
                try {
                    let response = await api.get(`${context.state.entityName}/location/${id}`);
                    context.state.loading = false;
                    return response.data;
                } catch (error) {
                    console.error(error);
                    context.state.loading = false;
                    return Promise.reject(error);
                }
            },
        })
    }
}

export default new ScheduleStore().createStore()