
import { ApprovalDto, CxEntityType } from "@/types/dto";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        route: String,
        entityType: {
            type: String as PropType<CxEntityType>,
            default: CxEntityType.GLOBAL
        }
    },
    data() {
        return {
            approvalData: [] as any[]
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        buttonLabel() {
            return this.$cx.tF('approval', 'pendingCount', [this.approvalData.length])
        },
        headerLabel() {
            let newCount = 0
            let changedCount = 0
            this.approvalData.forEach(d => {
                if (d.entityId == null)
                    newCount++
                else
                    changedCount++
            })
            return this.$cx.tF('approval', 'header', [newCount, changedCount])
        }
    },
    methods: {
        showOverlay(ev: any) {
            (this.$refs as any)["pendingDatasets"].toggle(ev)
        },
        load() {
            this.$store
                .dispatch(`approval/getNewDatasets`, this.entityType)
                .then((data) => {
                    this.approvalData = data
                })
                .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
        },
        getTitle(data: ApprovalDto) {
            return data.data.name
        },
        onOpenDataset(data: ApprovalDto) {
            if (data.entityId == null)
                this.$cx.goToApproval(this.route!, data.id!.toString())
            else
                this.$cx.goToById(this.route!, data.entityId)
        }
    }
})
