

import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";
import Button from "primevue/button";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        route: {
            type: String,
            default: ''
        },
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        }
    },
    computed: {
        classes() {
            if (this.$auth.permits(this.grant.perm, this.grant.lvl))
                return 'pi pi-plus'
            return 'pi pi-lock'
        }
    }
});
