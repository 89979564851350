
import { required } from "@/_config/ui-framework";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { CiscoMerakiDeviceDto } from "@/types/dto";

export default defineComponent({
    setup: () => ({ v$: useVuelidate() as any }),

    validations() {
        return {
            entity: {
                networkId: { required, $autoDirty: true },
                organizationId: { required, $autoDirty: true },
                token: { required, $autoDirty: true },
            },
        };
    },

    data() {
        return {
            entity: {} as CiscoMerakiDeviceDto,
            serialsArray: [{ value: "" }],
            result: [] as any,
        }
    },

    methods: {
        claim() {
            const templateName = "Meraki_network_claim_device";

            if (this.serialsArray.length > 0) {
                this.entity.serials = this.serials();
            }

            this.claimDevice(templateName, this.entity)
                .then(response => this.result = response)
                .catch(error => {
                    console.error('There was a problem fetching the data:', error);
                });
        },

        claimDevice(templateName: string, merakiDeviceDto: any) {
            return this.$store.dispatch("ciscoMerakiDevice/claim", {
                templateName: templateName,
                merakiDto: merakiDeviceDto
            });
        },

        addSerial() {
            this.serialsArray.push({ value: "" });
        },

        serials() {
            /* Afterwards the python script removed the quotes. 
            To prevent a script error add single quotes
            */
            return this.serialsArray.map(serial => `'${serial.value}'`);
        }
    },
});
