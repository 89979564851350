
import { ApprovalState } from "@/types/approval-state";
import { CxEntityType } from "@/types/dto";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        entity: Object,
        approvalState: {
            type: Object as PropType<ApprovalState>,
            default: new ApprovalState(CxEntityType.GLOBAL)
        },
        isNewDataset: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            qmBtnId: this.$cx.createUid(),
            modifiedValues: {} as { [key: string]: any }
        };
    },
    computed: {
        changeCount() {
            let count = Object.keys(this.approvalState.changes).length
            if (count > 0)
                return ` (${count})`
            return ''
        }
    },
    methods: {
        onQmButtonVisible(ev: any) {
            setTimeout(() => {
                ev.el.click()
            }, 500)
        },
        showPanel(ev: any) {
            (this.$refs as any)["approvalPanel"].toggle(ev)
        },
        reset() {
            this.approvalState.isViewingData = false
            this.approvalState.changes = {}
        },
        onToggleApproval() {

            this.approvalState.isViewingData = !this.approvalState.isViewingData

            this.approvalState.changes = this.$cx.deltaChanges(this.entity, this.approvalState.approvalData.data)

            this.$emit("viewChanged", this.approvalState.approvalData.data, this.approvalState.isViewingData)
            this.$emit("changed", Object.assign({}, this.approvalState.changes))
        },
        apply(entity: any, data: any) {
            Object.keys(data).forEach(key => {
                entity[key] = data[key]
            })
        }
    }
});
