
import {
  CxTableConfig,
  CxTableColumn,
  CxDataType,
} from "@/types/cx-table-config";
import { LocationCategoryRolePortRelDto, LocationCategoryRoleStateDto } from "@/types/dto";
import { maxLength, required, vMaxLen } from "@/_config/ui-framework";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {
        name: { required, maxLength: maxLength(vMaxLen.name), $autoDirty: true },
        description: { maxLength: maxLength(vMaxLen.description), $autoDirty: true },
      },
    };
  },
  data() {
    return {
      entity: {} as any,
      initialState: {} as any,
      roles: [] as any[],
      isAddRoleDialogVisible: false,
      stateRoles: [] as any[],
      selectedRole: {} as any,
      selectedPort: {} as any,
      selectedTargetRole: {} as any,
      selectedTargetPort: {} as any,
      connections: [] as LocationCategoryRolePortRelDto[],
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    isEditing() {
      return this.id != null;
    },
    deviceRoleTableConfig() {
      return new CxTableConfig([
        new CxTableColumn("deviceRole", this.$t('deviceRole', 'lb'), CxDataType.GetName),
        new CxTableColumn("minCount", this.$c('minCount')),
        new CxTableColumn("maxCount", this.$c('maxCount')),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
    connectionTableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn("source", "", CxDataType.Custom),
        new CxTableColumn("target", "", CxDataType.Custom)
      ]);
      // tc.filters = this.filters;
      return tc;
    },
    targetRouterOptions() {
      return this.stateRoles.filter(s => s != this.selectedRole)
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("locationCategory/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));

        this.loadRoles()
        this.loadConnections()
      }
    },
    async save(goBack: Boolean) {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      if (this.isEditing) {
        this.$store
          .dispatch("locationCategory/save", this.entity)
          .then(() => {
            this.$cx.notifySaved(this.$t("locationCategory", "lb"));
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("locationCategory")
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("locationCategory/create", this.entity)
          .then((newEntity: any) => {
            this.$cx.notifyCreated(this.$t("locationCategory", "lb"));
            this.entity = newEntity;
            this.initialState = this.$cx.getState(this.entity);
            if (goBack)
              this.$cx.goTo("locationCategory")
            else
              this.$cx.goToById("locationCategoryEditor", newEntity.id!);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },

    onReload() {
      this.loadRoles()
      this.loadConnections()
    },

    loadRoles() {
      this.$store
        .dispatch("locationCategory/getRoles", this.id)
        .then((roles) => {
          this.roles = roles;
        });
    },
    onDeleteRole(data: any) {
      (<any>this.$refs)['confirmDelete'].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("deviceRole", "lb"))
        this.onReload()
      });
    },

    // LocationCategoryRolePortRel / State
    loadPortState() {
      this.$store.dispatch("locationCategory/getState", this.id)
        .then(states => {
          // map the flat state into an object hierarchy:
          // { Role -> Ports } where Roles- and Port-Entries get extrapolated by their count
          let stateMap = {} as any
          states.forEach((state: LocationCategoryRoleStateDto) => {
            if (!(state.locationCategoryRoleName! in stateMap)) {
              stateMap[state.locationCategoryRoleName!] = {
                id: state.locationCategoryRoleId!,
                name: state.locationCategoryRoleName,
                minCount: state.minRoleCount,
                maxCount: state.maxRoleCount,
                ports: {}
              }
            }

            if (!(state.devicePortUseName! in stateMap[state.locationCategoryRoleName!].ports)) {
              stateMap[state.locationCategoryRoleName!].ports[state.devicePortUseName!] = {
                id: state.devicePortUseId,
                name: state.devicePortUseName,
                count: state.devicePortUseCount
              }
            }
          })

          this.stateRoles.length = 0
          Object.keys(stateMap).forEach(role => {
            let ports = [] as any[]
            Object.keys(stateMap[role].ports).forEach((port: any) => {
              for (let i = 0; i < stateMap[role].ports[port].count; i++) {
                ports.push({
                  label: `${stateMap[role].ports[port].name} #${i}`,
                  id: stateMap[role].ports[port].id,
                  index: i
                })
              }
            })
            for (let i = 0; i < stateMap[role].maxCount; i++) {
              let roleId = stateMap[role].id
              // subtract intersections from existing role and port connections
              let pts = ports.filter(p => {
                return !this.connections.some(o =>
                  o.sourceRole!.id == roleId
                  && o.sourceRoleIndex == i
                  && o.sourcePortUse!.id == p.id
                  && o.sourcePortIndex == p.index)
                  && !this.connections.some(o =>
                    o.targetRole!.id == roleId
                    && o.targetRoleIndex == i
                    && o.targetPortUse!.id == p.id
                    && o.targetPortIndex == p.index)
              })

              this.stateRoles.push({
                label: `${stateMap[role].name} #${i}`,
                id: stateMap[role].id,
                ports: [...pts],
                index: i
              })
            }
          })
        })
    },

    // Connections
    loadConnections() {
      this.$store
        .dispatch("locationCategory/getConnections", this.id)
        .then((connections) => {
          this.connections = connections;
          this.loadPortState()
          this.selectedRole = {}
          this.selectedTargetRole = {}
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },

    onCreateConnection() {
      this.$store
        .dispatch("locationCategory/createConnection", {
          locationCategory: { id: this.id },
          sourcePortIndex: this.selectedPort.index,
          sourceRoleIndex: this.selectedRole.index,
          sourcePortUse: { id: this.selectedPort.id },
          sourceRole: { id: this.selectedRole.id },
          targetPortIndex: this.selectedTargetPort.index,
          targetRoleIndex: this.selectedTargetRole.index,
          targetRole: { id: this.selectedTargetRole.id },
          targetPortUse: { id: this.selectedTargetPort.id }
        } as LocationCategoryRolePortRelDto)
        .then(() => {
          this.$cx.notifyCreated(this.$t("connection", "lb"))
          this.loadConnections()
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("error")));
    },

    onDeleteConnection(connection: any) {
      this.$cx.confirmDeleteDetail(
        this.$c('connection'),
        () => {
          this.$store
            .dispatch("locationCategory/deleteConnection", { id: connection.id, categoryId: this.id })
            .then(() => {
              this.$cx.notifyDeleted(this.$t("connection", "lb"))
              this.load()
            })
            .catch((error) => this.$cx.error(error, this.$cx.e("deleting")));
        })
    },
  },
});
