

import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";
import Button from "primevue/button";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        }
    },
    emits: ['click'],
    computed: {
        disabled() {
            if (this.grant == null) return false
            if (this.grant.perm == CxPermission.NONE) return false
            return !this.$auth.permits(this.grant.perm, this.grant.lvl)
        }
    }
});
