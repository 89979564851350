import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cx-form-h-30" }
const _hoisted_2 = {
  key: 0,
  class: "ml-3 pi pi-check",
  style: {"color":"green"}
}
const _hoisted_3 = {
  key: 1,
  class: "ml-3 pi pi-times",
  style: {"color":"red"}
}
const _hoisted_4 = {
  key: 0,
  class: "ml-3 pi pi-times",
  style: {"color":"red"}
}
const _hoisted_5 = { class: "cx-chips" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_save_button = _resolveComponent("cx-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_multi_chip_select = _resolveComponent("cx-multi-chip-select")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    loading: _ctx.$store.state.compliance.loading,
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'compliance'),
    detail: _ctx.entity.name
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_save_button, {
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('compliance')))
      }, null, 8, ["isEditing", "onSave"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_cx_label, {
          label: _ctx.$c('name'),
          v$: _ctx.v$.entity.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$c('name_ph'),
              modelValue: _ctx.entity.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _: 1
        }, 8, ["label", "v$"]),
        _createVNode(_component_cx_label, {
          label: _ctx.$t('rule', 'lb_pl'),
          prop: "rules"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_cx_multi_chip_select, {
              modelValue: _ctx.entity.rules,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.rules) = $event)),
              store: "rule",
              placeholder: _ctx.$t('rule', 'ph_pl'),
              dropdownOptions: {
                        route: 'ruleEditor'
                    }
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _createVNode(_component_DataTable, {
        expandedRows: _ctx.expandedRows,
        "onUpdate:expandedRows": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expandedRows) = $event)),
        value: _ctx.complianceResults,
        dataKey: "id"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$t('locationDevice', 'lb'),
            field: "locationDeviceName",
            sortable: ""
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('location', 'lb'),
            field: "locationName",
            sortable: ""
          }, null, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('compliance', 'compliant'),
            field: "compliant",
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.compliant)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : _createCommentVNode("", true),
              (!data.compliant && !data.notChecked)
                ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('compliance', 'notChecked'),
            field: "notChecked",
            sortable: ""
          }, {
            body: _withCtx(({ data }) => [
              (data.notChecked)
                ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('compliance', 'rules'),
            field: "items"
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.items || [], (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "cx-chip",
                    key: i
                  }, _toDisplayString(item.ruleName) + " (" + _toDisplayString(item.deviceFamilyGroupName) + ") ", 1))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["expandedRows", "value"])
    ]),
    _: 1
  }, 8, ["loading", "title", "detail"]))
}