import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid cx-config-editors" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "cx-vartree-node" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "flex justify-content-between align-items-center" }
const _hoisted_7 = {
  key: 0,
  class: "config-errors"
}
const _hoisted_8 = { class: "flex justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cx_approval = _resolveComponent("cx-approval")!
  const _component_cx_approval_save_button = _resolveComponent("cx-approval-save-button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_cx_label = _resolveComponent("cx-label")!
  const _component_cx_dropdown = _resolveComponent("cx-dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_cx_multi_chip_select = _resolveComponent("cx-multi-chip-select")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Tree = _resolveComponent("Tree")!
  const _component_Button = _resolveComponent("Button")!
  const _component_cx_code_input = _resolveComponent("cx-code-input")!
  const _component_cx_config_dialog = _resolveComponent("cx-config-dialog")!
  const _component_cx_page = _resolveComponent("cx-page")!

  return (_openBlock(), _createBlock(_component_cx_page, {
    title: _ctx.$cx.editorTitle(_ctx.isEditing, 'configuration'),
    detail: _ctx.entity.name,
    loading: _ctx.$store.state.configuration.loading
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_cx_approval, {
        approvalState: _ctx.approvalState,
        ref: "approval",
        entity: _ctx.entity,
        onViewChanged: _ctx.onApprovalViewChanged,
        isNewDataset: _ctx.isApproval
      }, null, 8, ["approvalState", "entity", "onViewChanged", "isNewDataset"]),
      _createVNode(_component_cx_approval_save_button, {
        approvalState: _ctx.approvalState,
        isEditing: _ctx.isEditing,
        onSave: _ctx.save,
        entity: _ctx.entity,
        onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$cx.goTo('configuration'))),
        initialEntity: _ctx.initialEntity,
        disableSaveAndBack: true,
        onStateChanged: _ctx.onApprovalChanged,
        v$: _ctx.v$,
        isNewDataset: _ctx.isApproval,
        grant: {
          perm: _ctx.$perm.CONFIGURATION,
          lvl: _ctx.$lvl.WRITE
        }
      }, null, 8, ["approvalState", "isEditing", "onSave", "entity", "initialEntity", "onStateChanged", "v$", "isNewDataset", "grant"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Accordion, {
        multiple: true,
        "active-index": _ctx.accordionIndizes
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AccordionTab, {
            header: _ctx.$t('configuration', 'header')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_label, {
                label: _ctx.$c('name'),
                v$: _ctx.v$.entity.name,
                approvalState: _ctx.approvalState,
                prop: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    modelValue: _ctx.entity.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.entity.name) = $event)),
                    placeholder: _ctx.$c('name_ph'),
                    disabled: !_ctx.approvalState.formEnabled
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              }, 8, ["label", "v$", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('manufacturer', 'lb'),
                approvalState: _ctx.approvalState,
                prop: "manufacturer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_dropdown, {
                    modelValue: _ctx.entity.manufacturer,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.entity.manufacturer) = $event)),
                    store: "manufacturer",
                    optionLabel: "name",
                    disabled: !_ctx.approvalState.formEnabled,
                    placeholder: _ctx.$t('manufacturer', 'ph'),
                    dropdownOptions: {
              route: 'manufacturerEditor',
              getId: (en) => en?.id,
              editable: true
            }
                  }, null, 8, ["modelValue", "disabled", "placeholder", "dropdownOptions"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$c('weight'),
                v$: _ctx.v$.entity.weight,
                approvalState: _ctx.approvalState,
                prop: "weight"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputNumber, {
                    placeholder: _ctx.$c('weight_ph'),
                    modelValue: _ctx.entity.weight,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.entity.weight) = $event)),
                    showButtons: true,
                    maxFractionDigits: 0,
                    disabled: !_ctx.approvalState.formEnabled
                  }, null, 8, ["placeholder", "modelValue", "disabled"])
                ]),
                _: 1
              }, 8, ["label", "v$", "approvalState"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_AccordionTab, {
            header: _ctx.$t('configuration', 'filter')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_label, {
                label: _ctx.$t('location', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "locations"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.locations,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.entity.locations) = $event)),
                    store: "location",
                    placeholder: _ctx.$t('location', 'ph_pl'),
                    disabled: !_ctx.approvalState.formEnabled,
                    dropdownOptions: {
              route: 'locationEditor'
            }
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('locationType', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "locationTypes"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.locationTypes,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.entity.locationTypes) = $event)),
                    store: "locationType",
                    disabled: !_ctx.approvalState.formEnabled,
                    placeholder: _ctx.$t('locationType', 'ph_pl'),
                    dropdownOptions: {
              route: 'locationTypeEditor'
            }
                  }, null, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('locationCategory', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "locationCategories"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.locationCategories,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.entity.locationCategories) = $event)),
                    store: "locationCategory",
                    disabled: !_ctx.approvalState.formEnabled,
                    placeholder: _ctx.$t('locationCategory', 'ph_pl'),
                    dropdownOptions: {
              route: 'locationCategoryEditor'
            }
                  }, null, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('deviceRole', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "roles"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.roles,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.entity.roles) = $event)),
                    store: "deviceRole",
                    placeholder: _ctx.$t('deviceRole', 'ph_pl'),
                    disabled: !_ctx.approvalState.formEnabled,
                    dropdownOptions: {
              route: 'deviceRoleEditor'
            }
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('deviceTemplate', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "templates"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.templates,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.entity.templates) = $event)),
                    store: "deviceTemplate",
                    placeholder: _ctx.$t('deviceTemplate', 'ph_pl'),
                    disabled: !_ctx.approvalState.formEnabled,
                    dropdownOptions: {
              route: 'deviceTemplateEditor'
            }
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('deviceFamily', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "families"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.families,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.entity.families) = $event)),
                    store: "deviceFamily",
                    disabled: !_ctx.approvalState.formEnabled,
                    placeholder: _ctx.$t('deviceFamily', 'ph_pl'),
                    dropdownOptions: {
              route: 'deviceFamilyEditor'
            }
                  }, null, 8, ["modelValue", "disabled", "placeholder"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"]),
              _createVNode(_component_cx_label, {
                label: _ctx.$t('deviceModel', 'lb_pl'),
                approvalState: _ctx.approvalState,
                prop: "models"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cx_multi_chip_select, {
                    modelValue: _ctx.entity.models,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.entity.models) = $event)),
                    store: "deviceModel",
                    placeholder: _ctx.$t('deviceModel', 'ph_pl'),
                    disabled: !_ctx.approvalState.formEnabled,
                    dropdownOptions: {
              route: 'deviceModelEditor'
            }
                  }, null, 8, ["modelValue", "placeholder", "disabled"])
                ]),
                _: 1
              }, 8, ["label", "approvalState"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["active-index"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('configuration', 'variable')), 1),
          _createVNode(_component_Tree, {
            class: "cx-vartree",
            value: _ctx.variableGraph,
            filter: true,
            filterMode: "lenient",
            scrollHeight: "calc(100vh - 261px)",
            style: {"min-height":"calc(100vh - 204px)"},
            expandedKeys: { 'variable': true },
            filterPlaceholder: _ctx.$c('filter')
          }, {
            default: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(slotProps.node.label), 1),
                (slotProps.node.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(slotProps.node.value), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["value", "filterPlaceholder"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('configuration', 'configurationSnippet')), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_Button, {
                class: "p-button-primary",
                icon: "pi pi-bolt",
                onClick: _ctx.compileTemplate
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                class: "p-button-primary ml-1",
                icon: "pi pi-book",
                onClick: _ctx.compileLastLocationDevice
              }, null, 8, ["onClick"])
            ])
          ]),
          _createVNode(_component_cx_label, {
            approvalState: _ctx.approvalState,
            prop: "configurationText",
            nomargin: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_code_input, {
                height: "calc(100vh - 204px)",
                modelValue: _ctx.entity.configurationText,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.entity.configurationText) = $event)),
                lang: "django",
                disabled: !_ctx.approvalState.formEnabled
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["approvalState"]),
          (_ctx.errors.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.errors), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass((_ctx.isNoConfigColumnExpanded) ? 'col-4' : 'cx-no-config-collapsed col-1')
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('configuration', 'noConfigurationSnippet')), 1),
            _createVNode(_component_Button, {
              class: "p-button-primary",
              icon: "pi pi-eye",
              onClick: _ctx.toggleNoConfigColumn
            }, null, 8, ["onClick"])
          ]),
          _createVNode(_component_cx_label, {
            approvalState: _ctx.approvalState,
            prop: "noConfigurationText",
            nomargin: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_cx_code_input, {
                height: "calc(100vh - 204px)",
                modelValue: _ctx.entity.noConfigurationText,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.entity.noConfigurationText) = $event)),
                lang: "django",
                disabled: !_ctx.approvalState.formEnabled
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }, 8, ["approvalState"])
        ], 2),
        _createVNode(_component_cx_config_dialog, {
          ref: "configDialog",
          showSaveHint: true,
          highlightConfigId: _ctx.id
        }, null, 8, ["highlightConfigId"])
      ])
    ]),
    _: 1
  }, 8, ["title", "detail", "loading"]))
}