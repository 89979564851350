
import { MenuItem } from "primevue/menuitem";
import { defineComponent } from "vue";
import { Language } from "./types/dto";
import { defaultLocale, switchLocale } from "./_config/ui-framework";

// @ts-ignore
import config from './assets/env.js'

export default defineComponent({
  data() {
    return {
      isExpanded: this.$cx.getStorageBool("cx.nav", true),
      selectedLanguage: defaultLocale,
      languages: [
        { name: "DE", value: Language.DE },
        { name: "EN", value: Language.EN },
        { name: "FR", value: Language.FR },
      ],
    };
  },
  methods: {
    onToggleNav() {
      this.isExpanded = !this.isExpanded
      localStorage.setItem("cx.nav", this.isExpanded.toString())
    },
    onLanguageChanged($event: any) {
      switchLocale(this.$primevue, $event.value);
    },
    onReloadDropdown() {
      this.$store.state.ui.dropdownOptions.reloadFn();
      (this.$refs as any).dropdownOptions.hide();
    },
    onEditDropdownEntry() {
      this.$cx.goToByIdNewTab(
        this.$store.state.ui.dropdownOptions.options.route,
        this.$store.state.ui.dropdownOptions.options.getId(
          this.$store.state.ui.dropdownOptions.value))
    },
    onCreateDropdownEntry() {
      this.$cx.goToNewTab(this.$store.state.ui.dropdownOptions.options.route + 'New')
    },
    showUserOverlay(ev: any) {
      (this.$refs as any)["userOverlay"].toggle(ev)
    },
    onLogout() {
      this.$cx.confirm("Logout bestätigen", "Möchten Sie sich wirklich abmelden?", () => {
        this.$auth.logout()
      })
    },
    isHighlighted(name: string) {

      //if (this.$route.name?.toString().toLocaleLowerCase().includes(name.toLowerCase()))
      //return 'cx-menu-highlight'


      if (this.$route.meta == null) return ""
      if (!("menu" in this.$route.meta)) return ""
      if ((this.$route.meta as any)["menu"].toString().toLowerCase() == name.toLowerCase())
        return 'cx-menu-highlight'

      return ""
    }
  },
  watch: {
    "$store.state.ui.dropdownOptions": function () {
      (this.$refs as any).dropdownOptions.toggle(this.$store.state.ui.dropdownOptions.event)
    },
  },
  computed: {
    getToolbarItems() {
      let items = [
        {
          label: this.$t("dashboard", 'lb'),
          icon: "pi pi-home",
          class: this.isHighlighted("dashboard"),
          to: "/",
        },
        {
          label: this.$t("softwareManagement", 'lb'),
          icon: "pi pi-cloud-download",
          class: this.isHighlighted("software"),
          to: "/softwareManagement",
        },
        {
          label: this.$t("compliance", 'lb'),
          icon: "pi pi-check",
          class: this.isHighlighted("compliance"),
          to: "/compliance-overview",
        },
        {
          label: this.$t("location", "lb_pl"),
          icon: "pi pi-building",
          class: this.isHighlighted("location"),
          to: "/location",
        },
        {
          label: this.$t("configuration", "lb_pl"),
          icon: "pi pi-book",
          class: this.isHighlighted("configuration"),
          to: "/configuration",
        },
        {
          label: this.$t("ipPool", "lb_pl"),
          description: "IP-Adressenverwaltung",
          class: this.isHighlighted("ipPool"),
          icon: "pi pi-users",
          to: "/ipPool",
        },
        {
          label: this.$t("variable", "lb_pl"),
          icon: "pi pi-code",
          class: this.isHighlighted("variable"),
          to: "/variables",
        }] as MenuItem[]

      if ((config.VUE_APP_ENABLE_SATELLITE || process.env.VUE_APP_ENABLE_SATELLITE) == 1) {
        items.push({
          label: this.$t("satellite", 'lb'),
          class: this.isHighlighted("satellite"),
          icon: "pi pi-globe",
          to: "/satellite",
        })
      }

      items = items.concat([{
        label: this.$t("job", "lb_pl"),
        icon: "pi pi-history",
        class: this.isHighlighted("job"),
        to: "/jobs",
      }, {
        label: this.$t("log", "lb_pl"),
        icon: "pi pi-bars",
        class: this.isHighlighted("logs"),
        to: "/logs",
      }, {
        label: this.$t("aci", 'lb'),
        icon: "pi pi-bolt",
        class: this.isHighlighted("apics"),
        to: "/apics",
      },
      {
        label: this.$t("meraki", 'lb'),
        icon: "pi pi-map",
        class: this.isHighlighted("meraki"),
        to: "/meraki",
      },
      {
        label: this.$t("management", "lb"),
        class: this.isHighlighted("management"),
        icon: "pi pi-book",
        to: "/management",
      },
      {
        label: this.isExpanded ? this.$t("common", "collapse") : '',
        icon: 'pi' + (this.isExpanded ? ' pi-angle-double-left' : ' pi-angle-double-right'),
        command: () => this.onToggleNav()
      },
      ] as MenuItem[]);
      return items
    },
  },
});
