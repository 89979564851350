import { cxUtils } from "@/types/cx-utils";
import axios from "axios";

// @ts-ignore
import config from '../assets/env.js'

const api = axios.create({
  baseURL: config.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
  headers: {
    LANG: '',
    Authorization: ''
  },
  withCredentials: true
});

if (process.env.VUE_APP_USE_AUTH != 1) {
  delete ((api.defaults.headers as any)["Authorization"])
  api.defaults.withCredentials = false
} else {
  (api.defaults.headers as any)["Authorization"] = 'Bearer ' + cxUtils.getStorage("cx.token", "")
}

export { api };
