import { IPPoolDto, IPPoolVirtualNetworkDto, SubnetParamsRequest } from "@/types/dto";
import CxApi, { ApiIdParams } from "@/types/cx-api";
import { api } from "@/_config/api";
import { CxPaging } from "@/types/cx-paging";

const entityName = "ipPool";
const cxApi = new CxApi<IPPoolDto>(entityName);

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {
        async getWithFilter(context: any, props: any) {
            try {
                context.state.loading = true;
                let response = await api.get(`${entityName}/filter`, {
                    params: {
                        ...{
                            page: props.page,
                            size: props.size
                        }, ...props.filters
                    }
                });
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getAll(context: any) {
            context.state.loading = true;
            try {
                let response = await cxApi.getAll();
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getById(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await cxApi.getById(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async save(context: any, entity: IPPoolDto) {
            context.state.loading = true;
            try {
                let response = await cxApi.save(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async create(context: any, params: any) {
            context.state.loading = true;
            try {
                let response = await api.post(`${entityName}?type=` + params.type, params.entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async deleteWithConfirm(context: any, props: any) {
            context.state.loading = true;
            try {
                let response = await api.delete(
                    `ipPool/${props.id}?confirm=${props.confirm}`
                );
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async refresh(context: any, ipPoolDto: IPPoolDto) {
            try {
                let response = await api.post(entityName + '/refresh', ipPoolDto);
                return response.data;
            } catch (error) {
                console.error(error);
                return Promise.reject(error);
            }
        },
        async getSubnet(context: any, paging: CxPaging) {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/${paging.id}/subnet?page=${paging.page}&size=${paging.size}`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async createSubnet(context: any, params: ApiIdParams<SubnetParamsRequest>) {
            context.state.loading = true;
            try {
                let response = await api.post(`${entityName}/${params.id}/subnet`, params.entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async deleteSubnet(context: any, params: any) {
            context.state.loading = true;
            try {
                let response = await api.delete(`${entityName}/${params.poolId}/subnet/${params.subnetId}`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },

        // VirtualNetworks, Vlans
        async getAllVlans(context: any, id: any) {
            context.state.loading = true;
            try {
                let response = await api.get(`${entityName}/${id}/virtualNetworks`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },

        async createVlan(context: any, params: ApiIdParams<IPPoolVirtualNetworkDto>) {
            context.state.loading = true;
            try {
                let response = await api.post(`${entityName}/${params.id}/virtualNetworks`, params.entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },

        async deleteVlan(context: any, params: any) {
            context.state.loading = true;
            try {
                let response = await api.delete(`${entityName}/${params.poolId}/virtualNetworks/${params.virtualNetworkId}`);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
    }
};

