export enum CxVariableType {
    Global = "Global",
    DeviceRole = "DeviceRole",
    DeviceModel = "DeviceModel",
    DeviceFamily = "DeviceFamily",
    Manufacturer = "Manufacturer",
    Location = "Location",
    LocationType = "LocationType",
    LocationDevice = "LocationDevice",
    LocationCategory = "LocationCategory"
}
