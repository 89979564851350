import CxApi from "@/types/cx-api";
import { CiscoAciDto, CiscoAciQueryRequest } from "@/types/dto";
import { api } from "@/_config/api";

const entityName = "aci";
const aciApiName = "aci/settings"
const aciApi = new CxApi<CiscoAciDto>(aciApiName);

export default {
    namespaced: true,
    state: {
        loading: false
    },
    actions: {

        // ACI Settings

        async getAcis(context: any) {
            context.state.loading = true;
            try {
                let response = await aciApi.getAll();
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async getAciById(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await aciApi.getById(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async saveAci(context: any, entity: CiscoAciDto) {
            context.state.loading = true;
            try {
                let response = await aciApi.save(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async createAci(context: any, entity: CiscoAciDto) {
            context.state.loading = true;
            try {
                let response = await aciApi.create(entity);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
        async deleteAci(context: any, id: number) {
            context.state.loading = true;
            try {
                let response = await aciApi.delete(id);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },

        // Worker-Commands

        async queryAci(context: any, params: CiscoAciQueryRequest) {
            context.state.loading = true;
            try {
                let response = await api.post(`${entityName}/queryAci`, params);
                context.state.loading = false;
                return response.data;
            } catch (error) {
                console.error(error);
                context.state.loading = false;
                return Promise.reject(error);
            }
        },
    }
};

