
import {
  CxDataType,
  CxTableColumn,
  CxTableConfig,
} from "@/types/cx-table-config";
import { CxExportType } from "@/types/dto";
import { defineComponent } from "vue";

export default defineComponent({
  props: {},
  data() {
    return {
      ipPools: []
    };
  },
  computed: {
    tableConfig() {
      return new CxTableConfig([
        new CxTableColumn("name", this.$c("name")),
        new CxTableColumn("networkType", this.$c("networkType")),
        new CxTableColumn("ipPool", "IP-Pool", CxDataType.Custom),
        new CxTableColumn("networkCount", this.$c("networkCount")),
        new CxTableColumn("hostCount", this.$c("hostCount")),
        new CxTableColumn("audit", this.$c("audit"), CxDataType.Audit),
      ]);
    },
  },
  methods: {
    load(filters: any) {
      this.$store
        .dispatch("ipPool/getWithFilter", filters)
        .then((ipPools) => {
          this.ipPools = ipPools;
        })
        .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
    },
    onExport() {
      this.$store.dispatch("ipPool/export", CxExportType.CSV);
    },
    onDelete(data: any) {
      (<any>this.$refs)["confirmDelete"].onDelete(data.id, () => {
        this.$cx.notifyDeleted(this.$t("ipPool", "lb"));
        (<any>this.$refs)['tbl'].reload()
      })
    },
    onClearFilters() {
      (<any>this.$refs)['tbl'].clearFilters()
    }
  },
});
