
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    initials: {
      type: String,
      default: 'CX'
    }
  },
  data() {
    return {};
  },
});
