
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        gridItems() {
            return [
                {
                    title: this.$t('meraki', 'overviewNetworkTitle'),
                    elements: [
                        {
                            label: "Create network",
                            description: "create a network with the meraki api",
                            meta: "create network",
                            icon: "pi pi-sitemap",
                            to: "/meraki/networks",
                        }
                    ]
                },
                {
                    title: this.$t('meraki', 'overviewDeviceTitle'),
                    elements: [
                        {
                            label: "Network claims device(s)",
                            description: "meraki api to claim a device for a network",
                            meta: "network claims device(s)",
                            icon: "pi pi-sitemap",
                            to: "/meraki/devices",
                        }
                    ]
                },
            ]
        }
    }
});
