

import { CxGrant, CxPermission, CxPermissionLevel } from "@/types/cx-permission";
import Button from "primevue/button";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        route: {
            type: String,
            default: ''
        },
        id: {
            type: Number,
            default: -1
        },
        grant: {
            type: Object as PropType<CxGrant>,
            default: {
                perm: CxPermission.NONE,
                lvl: CxPermissionLevel.NONE
            } as CxGrant
        }
    },
    emits: ['click'],
    computed: {
        disabled() {
            if (this.grant == null) return false
            if (this.grant.perm == CxPermission.NONE) return false
            return !this.$auth.permits(this.grant.perm, this.$lvl.READ)
        }
    },
    methods: {
        click() {
            this.$emit('click')
            if (!this.$cx.isNullOrEmpty(this.route))
                this.$cx.goToById(this.route, this.id)
        }
    }
});
