
import { BLBExtenderDto } from "@/types/dto";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { CxTableColumn, CxTableConfig } from "@/types/cx-table-config";

export default defineComponent({
  props: {
    id: String,
  },
  setup: () => ({ v$: useVuelidate() as any }),
  validations() {
    return {
      entity: {},
    };
  },
  data() {
    return {
      entity: {} as any,
      initialState: {} as any,
      location: {} as any,
      deviceRole: {} as any,
      deviceTemplate: {} as any,
      hostname: "",
      managementIp: "",
      serials: "",
      variable: "",
      variableValue: "",
      blbExtenderDto: {} as BLBExtenderDto,
      files: [] as any,
      loading: false,
      isPreviewDialogVisible: false,
      tableHeader: [] as any,
      previewData: [] as any,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    id: function () {
      this.load();
    },
  },
  computed: {
    tableConfig() {
      let tc = new CxTableConfig([
        new CxTableColumn(this.tableHeader[0], this.tableHeader[0]),
        new CxTableColumn(this.tableHeader[1], this.tableHeader[1]),
        new CxTableColumn(this.tableHeader[2], this.tableHeader[2]),
        new CxTableColumn(this.tableHeader[3], this.tableHeader[3]),
        new CxTableColumn(this.tableHeader[4], this.tableHeader[4]),
        new CxTableColumn(this.tableHeader[5], this.tableHeader[5]),
        new CxTableColumn(this.tableHeader[6], this.tableHeader[6]),
      ]);
      return tc;
    },
    isEditing() {
      return this.id != null;
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.$cx.notifyUnsavedChanges(next, this.initialState, this.entity);
  },
  methods: {
    load() {
      if (this.isEditing) {
        this.$store
          .dispatch("blb-extender/getById", this.id)
          .then((entity) => {
            this.entity = entity;
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("loading")));
      }
    },
    async save() {
      if (await this.$cx.notifyValidationError(this.v$)) return;

      this.blbExtenderDto.locationId = { id: this.location?.id };
      this.blbExtenderDto.deviceRoleId = { id: this.entity?.deviceRole?.id };
      this.blbExtenderDto.deviceTemplateId = {
        id: this.entity?.deviceTemplate?.id,
      };
      this.blbExtenderDto.managementIp = this.managementIp;
      this.blbExtenderDto.hostname = this.hostname;
      this.blbExtenderDto.variable = this.variable;
      this.blbExtenderDto.variableValue = this.variableValue;
      this.blbExtenderDto.serialNumbers = this.serials?.split(/\r?\n/);

      if (this.isEditing) {
        this.$store
          .dispatch("blb-extender/save", this.blbExtenderDto)
          .then(() => {
            this.$cx.notifySaved(this.$t("deviceFamily", "lb"));
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("saving")));
      } else {
        this.$store
          .dispatch("blb-extender/create", this.blbExtenderDto)
          .then(() => {
            this.$cx.notifyCreated(this.$t("locationDevice", "lb"));

            this.reset();
            this.initialState = this.$cx.getState(this.entity);
          })
          .catch((error) => this.$cx.error(error, this.$cx.e("creating")));
      }
    },

    onFileChanged($ev: any) {
      if ((<any>this.$refs["file"]).files.length > 0) {
        this.loading = true;

        this.files = (<any>this.$refs["file"]).files.find(
          (x: any) => x !== undefined
        );
        let formData = new FormData();
        formData.append("import-csv", this.files);
      }
    },

    reset() {
      this.entity = {};
      this.location = {};
      this.managementIp = "";
      this.hostname = "";
      this.variable = "";
      this.variableValue = "";
      this.serials = "";
    },

    onOpenPreview() {
      this.isPreviewDialogVisible = true;
    },
  },
});
