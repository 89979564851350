
import { CxDataType, CxTableConfig } from "@/types/cx-table-config";
import { CxImportResult, LocationDto } from "@/types/dto";
import { PropType, defineComponent } from "vue";

export default defineComponent({
    props: {
        store: {
            type: String,
            default: "",
        },
        importTableConfig: {
            type: Object as PropType<CxTableConfig>
        }
    },
    data() {
        return {
            isVerified: false,
            delimiter: ",",
            files: [] as any[],
            importData: [] as any[],
            charset: 'UTF-8' as string,
            charsetOptions: [
                { label: 'UTF-8' }
            ],
            cellErrors: {} as any,
            columnErrors: {} as any,
            loading: false,
            globalErrors: '',
            CxDataType: CxDataType,
            imported: false,
            visible: false,
            hasErrors: false
        };
    },
    methods: {
        onImport(verify: boolean) {
            this.loading = true
            this.$store.dispatch(this.store + "/importCSV", {
                delimiter: this.delimiter,
                charset: this.charset,
                verify: verify,
                file: this.files[0]
            })
                .then((result) => {
                    this.cellErrors = result.cellErrors
                    this.importData = result.data
                    this.columnErrors = {}
                    this.globalErrors = result.globalErrors.join(', ')
                    this.isVerified = result.success
                    this.imported = result.imported

                    this.hasErrors = !result.success

                    if (this.imported)
                        this.$emit('reload')

                    Object.values(result.cellErrors).forEach((rowErr: any) => {
                        Object.keys(rowErr).forEach((colErr: any) => {
                            this.columnErrors[colErr] = 1
                        })
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getCellClass(colIdx: any, rowIdx: any) {
            let s = 'cx-import-cell '
            try {
                let cIdx = parseInt(colIdx.toString())
                let rIdx = parseInt(rowIdx.toString())
                if (rIdx in this.cellErrors) {
                    if (cIdx in this.cellErrors[rIdx])
                        s += 'cx-import-cell-err'
                }
            } finally {
                return s
            }
        },
        getCellError(colIdx: any, rowIdx: any) {
            let err = ''
            try {
                let cIdx = parseInt(colIdx.toString())
                let rIdx = parseInt(rowIdx.toString())
                if (rIdx in this.cellErrors) {
                    if (cIdx in this.cellErrors[rIdx])
                        err += this.cellErrors[rIdx][cIdx]
                }
            } finally {
                return err
            }
        },
        getColumnError(colIdx: any) {
            let cIdx = parseInt(colIdx.toString())
            if (cIdx in this.columnErrors) {
                return 'cx-import-col-err'
            }
        },
        onShow() {
            this.imported = false
            this.cellErrors = {}
            this.columnErrors = {}
            this.loading = false
            this.globalErrors = ''
            this.importData = []
            this.files.length = 0
            this.delimiter = ','
            this.isVerified = false
            this.hasErrors = false

            this.visible = true
        },
        onClose() {
            this.visible = false
        }
    }
});
